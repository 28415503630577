<template>
  <div class="body">
    <div v-if="showStripe" class="stripe_logo">
      <img src="/stripe-small-burple.png" style="width:150px;"/>
    </div>
    <a  v-if="showStripe" href="#"  @click.prevent="toggleModal" class="stripe_logo_label">
      Add a Stripe Account
    </a>
   <!--<div v-if="isRunningInCapacitor" class=""><a class="stripe_link_center" :href='"https://do-good.rallyhood.com/"  + this.theRallyId + "/edit"' target="_blank" >Add Stripe Accounts In Rallyhood Web</a></div>-->
    <div v-if="loading" class="loading">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
    <div class="well" style="text-align: left" v-if = "rallyAccounts && rallyAccounts.length > 0">      
      <div class="well_header">
        STRIPE ACCOUNTS 
      </div>
      <br/> (<a href="#" @click.prevent="reloadPage">refresh</a>) 
      <StripeAccount
          v-for="(account, $index) in rallyAccounts"
          :key="$index"
          :account="account"
          v-show="!loading" 
      >
      </StripeAccount>
    </div>
    <div :class="[{ open: modalStatus }, 'modal']">
      <div class="shadow" @click="toggleModal"></div>
      <div class="body" style="overflow: scroll;">
        <i class="fal fa-times exit" @click="toggleModal"></i> 
        <div class="heading">Set Up</div>
        <form @submit.prevent="handleSubmit">
          <div class="description">
            <p>Rallyhood has chosen Stripe as its payments processing platform because of its best-in-class security tools and certifications, as well as its years as the market-leader, empowering millions of business and individuals to collect and make payments.</p>
            <p>Once you set up your Stripe Account, you can collect money for Events, Dues, or Fundraising and more in Rallyhood.</p>
            <p>For more information about Stripe’s Security Certification and more, <a target="_blank" href="https://stripe.com/docs/security/stripe">https://stripe.com/docs/security/stripe</a></p>
            <p><strong>Register as an individual or a company (company requires an EIN number)</strong></p>
          </div>
          <div class="form-group inline-labels radios">
            <div class="form-group inline-labels radios">
              <label>
                <input v-model="account.attributes['entity']"  type="radio" name="entity" value="individual"/>
                <div class="circle"></div>
                Individual
              </label>
              <label>
                <input v-model="account.attributes['entity']" type="radio" name="entity" value="company"/>
                <div class="circle"></div>
                Company
              </label>
            </div>
          </div>
          <div class="form-group">
            <label>Account Name</label>
            <input  v-model="account.attributes['title']" placeholder="Ex: Volunteer Fundraising" type="text" />
          </div>
          <div class="form-group">
            <label>Billing Zip</label>
            <input  v-model="account.attributes['zip']" type="text" />
          </div>
          <div class="form-group">
            <label>State</label>
            <StateDropDown v-model="account.attributes['state']" id="state-select" />
          </div>
          <div v-if="filteredUserAccounts.length > 0" class="form-group">
            <label>Or import a Stripe Account</label>
            <select v-model="selectedAccount">
              <option>*** Add a Stripe Account</option>
              <option v-for="account in filteredUserAccounts" :value="account" :key="account.attributes['account-rally-id']">
                {{ account.attributes['title'] }}
              </option>
            </select>
          </div>
          <div class="actions" style="padding-bottom:25px;">
            <button class="secondary" @click="toggleModal">Cancel</button>
            <!-- Save button -->
            <button class="primary" v-if="!selectedAccount || selectedAccount === '*** Add a Stripe Account'" @click="createAccount">Save<i v-if="creating" class="fas fa-spinner fa-spin"/></button> 
            <!-- Import button -->
            <button class="primary" v-else @click="importAccount">Import</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import StateDropDown from "@/components/shared/StateDropDown.vue";
import {userInfo} from "@/common/user-info";

import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";

export default {
  components: {
    StateDropDown
  },
  name: "ManagePayments",
  props: ['rallyId', 'isDesktopSize'],
  data: function() {
    return {
      modalStatus: false,
      errorMessage: '',
      creating: false,
      account: {attributes: {}},
      selectedAccount: null,
      userAccountsLoaded: false,
      rallyAccountsLoaded: false,
      loading: true,
      isRunningInCapacitor: false
    };
  },
  computed: {
    ...mapState("rfins", ["accountsByRallyId", "accountsByUserId"]),
    ...mapState("rallies", ["allRalliesByRallyId"]),
    showStripe(){
      return this.allRalliesByRallyId[this.theRallyId] ? !this.allRalliesByRallyId[this.theRallyId].rally.attributes["hide-stripe"] : true
    },
    theRallyId(){
      return this.$route.params.rallyId
    },
    rallyAccounts() {
      if( this.rallyAccountsLoaded) {
        return this.accountsByRallyId[parseInt(this.$route.params.rallyId)].filter(acc => {
            return acc.attributes['account-type'] !== 'default';
          });
      }
      return [];
    },
    filteredUserAccounts() {
      if (this.userAccountsLoaded && this.rallyAccountsLoaded) {
        // Filter user accounts based on disabled attribute and master account ID
        if (this.rallyAccounts === undefined) {
          return this.accountsByUserId[userInfo().id].filter(userAccount => {
            return userAccount.attributes['disabled'] !== true;
          });
        } else {
          return this.accountsByUserId[userInfo().id].filter(userAccount => {
            return (
                userAccount.attributes['disabled'] !== true &&
                !this.rallyAccounts.some(rallyAccount =>
                    rallyAccount.attributes['master-account-id'] === userAccount.attributes['master-account-id']
                )
            );
          });
        }
      }
      return [];
    }
  },
  mounted() { 
    // Check if the app is running inside Capacitor
    this.isRunningInCapacitor = (Capacitor.getPlatform() == "android" || Capacitor.getPlatform() == "ios")
    this.loadData();
  },
  methods: {
    ...mapActions("rfins", ["fetchAccounts", "fetchAccountById", "fetchUserAccounts", "createStripeAccount", "importStripeAccount"]),
    reloadPage(){
      window.location.reload()
    },
    async loadData() {
      await Promise.all([
        this.fetchAccounts({ rallyId: parseInt(this.$route.params.rallyId), returnUrl: window.location.href }),
        this.fetchUserAccounts({ returnUrl: window.location.href })
      ]);

      this.rallyAccountsLoaded = true;
      this.userAccountsLoaded = true;

      this.loading = false;
    },
    toggleModal() {

    //  if (this.isFull){
      //  return
     // }
      this.modalStatus = !this.modalStatus;
    },
    handleSubmit() {
      // Prevent default form submission behavior
      // You can perform additional logic here if needed
    },
    async createAccount() {
      this.errorMessage = '';
      this.creating = true;
      const theReturnUrl = (Capacitor.getPlatform() == "android" || Capacitor.getPlatform() == "ios") ? 'https://rallyhood.com/stripe_close.html' : window.location.href
      const newAccount = await this.createStripeAccount({rallyId: this.rallyId, account: this.account, returnUrl: theReturnUrl});
      const fetchedNewAccount = await this.fetchAccountById({id: newAccount.id, returnUrl: theReturnUrl})
      this.creating = false;
      try {
        const stripeUrl = fetchedNewAccount.attributes['stripe-standard-enrollment-url']
        if (Capacitor.getPlatform() == "android" ||
            Capacitor.getPlatform() == "ios") {
            Browser.open({ url: stripeUrl });
            Browser.addListener('browserFinished', () => {
              window.location.reload()
            });
        } else {
          window.location.href = stripeUrl;
        }
      } catch (error) {
        console.log("ERROR RETRIEVING STRIPE ACCOUNT:", error);
        this.errorMessage = "Failed to retrieve Stripe account.";
      }
      this.creating = false;
    },
    async importAccount() {
      this.errorMessage = '';
      const selectedAccount = this.selectedAccount;
      const newAccount = await this.importStripeAccount({ rallyId: this.rallyId, account: selectedAccount, returnUrl: window.location.href });
      this.$store.dispatch('user/flashSuccess', 'Account successfully imported!');
      this.toggleModal()
      this.fetchAccounts({rallyId: this.rallyId, returnUrl: window.location.href})
    }
  }
}
</script>

<style scoped>

</style>