<template>
  <div>
    <RallyNav :rallyId="this.rallyId" />
    <main class="channel-settings">
      <ToolBar back="navUp" v-on:perform="perform" />
      <section class="channel-form">
        <form>
          <div class="sub-heading">Customize</div>
          <div>
            <div class="form-group half">
              <label for="name">Navbar Label</label>
              <input id="name" v-model="currentChannel.attributes['name']" type="text" style="height: 47px; position: relative; top: 1px;" />
            </div>
            <div class="form-group half">
              <label for="icon">Mobile Icon</label>
              <div :class="[{ open: multiButtonOpen }, 'multi-button']">
                <span class="option">
                  <i :class="['fal', selectedIcon]"></i>
                </span>
                <div
                  @click="toggleMultiButton"
                  class="dropdown"
                >
                  <i class="fas fa-sort-down icon"></i>
                </div>
                <ul class="additional">
                  <li
                    v-for="icon in icons"
                    :key="icon"
                    class="option"
                    @click="selectIcon(icon)"
                  >
                    <i :class="['fal', icon]"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr />
          <div class="sub-heading">Set Permissions</div>
          <div v-if="isContentChannel">
            <div class="form-group stacked-labels radios">
              <label>
                <input name="read_write" type="radio" value="Members" :checked="readWrite === 'all_members'" @click="setChannelPermission"/>
                <div class="circle"></div>
                All members may post here
              </label>
              <label>
                <input name="read_write" type="radio" value="Admin" :checked="readWrite === 'members_read'" @click="setChannelPermission"/>
                <div class="circle"></div>
                All members may view, but only Rally admin may post here
              </label>
            </div>
            <div class="form-group stacked-labels checkboxes" v-if="hasCommentsSettings">
              <label>
                <input type="checkbox" v-model="currentChannel.attributes['allow-comments']"/>
                <div class="square"></div>
                Allow comments
              </label>
            </div>
          </div>
          <div v-else>
            <div class="form-group stacked-labels radios">
              <label>
                <input name="read_write" type="radio" value="Admin" :checked="readWrite === 'members_read'" @click="setChannelPermission"/>
                <div class="circle"></div>
                Only members may view
              </label>
            </div>
          </div>
          <div class="actions">
            <button class="secondary" @click="navUp">Cancel</button>
            <button class="primary" @click.prevent="submit">Save</button>
          </div>
        </form>
      </section>
    </main>
  </div>
</template>

<script>

import { mapState, mapActions, mapGetters } from "vuex";
import { router } from "../common";

export default {
  data() {
    return {
      multiButtonOpen: false,
      icons: [
        'fa-home',
        'fa-comments',
        'fa-calendar-check',
        'fa-image',
        'fa-copy',
        'fa-link',
        'fa-map',
        'fa-list-ul',
        'fa-user',
        'fa-usd-circle',
        'fa-cog',
        'fa-question-circle',
      ],
      rallyId: null,
      selectedIcon: 'fa-truck',
    };
  },
  computed: {
    ...mapState("rallies", ["rallyChannelsByRallyId"]),
    channelSlug() {
      return this.$route.params.channelSlug;
    },
    currentChannel() {
      const channels = this.rallyChannelsByRallyId[parseInt(this.$route.params.rallyId)]
      const foundChannel = channels.find(channel => channel.attributes['slug'].toLowerCase() === this.channelSlug.toLowerCase());

      return foundChannel;
    },
    isContentChannel() {
      if (this.currentChannel.attributes['type'].toLowerCase() === 'directory' ||
          this.currentChannel.attributes['type'].toLowerCase() === 'groups' ||
          this.currentChannel.attributes['type'].toLowerCase() === 'members' ||
          this.currentChannel.attributes['type'].toLowerCase() === 'rallies') {
        return false
      }
      return true
    },
    hasCommentsSettings() {
      if(this.currentChannel.attributes['type'].toLowerCase() === 'messagewall') {
        return true
      }
      return false
    },
    readWrite() {
      if (this.currentChannel.attributes['members-read'] === 'admin') {
        return 'admin_only'
      } else if (this.currentChannel.attributes['members-write'] === 'admin') {
        return 'members_read'
      } else if (this.currentChannel.attributes['members-write'] === 'members' && this.currentChannel.attributes['members-read'] !== 'public') {
        return 'all_members'
      } else if  (this.currentChannel.attributes['members-read'] === 'public') {
        return 'public_read'
      }
      return 'all_members'
    }
  },
  created() {
    this.loadPageData();
    this.selectedIcon = this.currentChannel.attributes['icon']
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions("channels", ["updateChannel"]),
    loadPageData() {
      this.rallyId = parseInt(this.$route.params.rallyId);
    },
    navUp() {
      const path = `/${this.rallyId}/${this.channelSlug}`
      router.push(path);
    },
    perform(method, params) {
      params ? this[method](params) : this[method]();
    },
    selectIcon(icon) {
      this.selectedIcon = icon;
      this.currentChannel.attributes['icon'] = this.selectedIcon
      this.multiButtonOpen = false;
    },
    setChannelPermission(event) {
      const selectedValue = event.target.value;
      if (selectedValue == 'Members') {
        this.currentChannel.attributes['members-write'] = 'members'
        this.currentChannel.attributes['members-read']  = 'members'
      } else if (selectedValue == 'Admin') {
        this.currentChannel.attributes['members-write'] = 'admin'
        this.currentChannel.attributes['members-read']  = 'members'
      } else if (selectedValue == 'Public') {
        this.currentChannel.attributes['members-write'] = 'public'
        this.currentChannel.attributes['members-read']  = 'public'
      }
    },

    submit() {
      this.updateChannel({channel: this.currentChannel})
      this.$store.dispatch('user/flashSuccess', 'Channel settings updated!');
    },
    toggleMultiButton() {
      this.multiButtonOpen = !this.multiButtonOpen;
    },
  }
};
</script>
