import axios from "axios";
import {
    mergeById,
    sortByDate, RTAGS_URL, buildServiceParamMap, RCS_SHARE_URL
} from "../../common";

import { userInfo } from "../../common/user-info";
import { authHeader } from "../../common/auth-header";
import jwtInterceptor  from '../../common/jwt-interceptor';

const state = {
    rallyRelationshipsByTagTypeId: {},
    categoriesByRallyId: {}
};

const getters = {
    rallyRelationshipsByTagTypeId: state=> {
        return state.rallyRelationshipsByTagTypeId;
    },
    categoriesByRallyId: state => {
        return state.categoriesByRallyId;
    }
};

const actions = {
    async setRallyRelationshipsByTagTypeId({commit, state}, inparams) {
        commit("setRallyRelationshipsByTagTypeId", inparams);
    },

    async setCategoriesByRallyId({commit, state}, inparams) {
        commit("setCategoriesByRallyId", inparams);
    },

    //http://localhost:3020/api/v1/shares.json?content_uid=19023978&page%5Bsize%5D=200 response_code=200 return_code=ok total_time=0.306446
    async fetchCategoriesByRally({commit, state}, inparams) {
        const {rallyId} = inparams;
        const user = userInfo();
        var jsonparams = {
            context_id: rallyId,
            context_type: 'Rally',
            type: 'CommunityCategory',
            "page[number]": "1",
            "page[size]": "500",
        };
        var params = buildServiceParamMap(jsonparams);
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            url: RTAGS_URL() + '/tag-types',
            params
        };
        const response = await jwtInterceptor(requestOptions);

        this.dispatch("tags/setCategoriesByRallyId", {rallyId: rallyId, categories: response.data.data})
    },

    async fetchRallyRelationshipsByTagTypeId({commit, state}, inparams) {
        const {tagTypeId} = inparams;
        const user = userInfo();
        var jsonparams = {
            tag_type_id: tagTypeId,
            taggable_type: 'RallyRelationship',
            "page[number]": "1",
            "page[size]": "500",
        };
        var params = buildServiceParamMap(jsonparams);
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            url: RTAGS_URL() + '/tags',
            params
        };
        const response = await jwtInterceptor(requestOptions);

        this.dispatch("tags/setRallyRelationshipsByTagTypeId", {tagTypeId: tagTypeId, rallyRelationships: response.data.data})
    },
}

const mutations = {
    clearState: state => {
        state.rallyRelationshipsByTagTypeId = {};
        state.categoriesByRallyId = {};
    },
    setCategoriesByRallyId: (state, params) => {
        const {rallyId, categories} = params
        var byIdObj = {}
        byIdObj[rallyId] = categories
        state.categoriesByRallyId = {...state.categoriesByRallyId, ...byIdObj}
    },
    setRallyRelationshipsByTagTypeId: (state, params) => {
        const {tagTypeId, rallyRelationships} = params
        var byIdObj = {}
        byIdObj[tagTypeId] = rallyRelationships
        state.rallyRelationshipsByTagTypeId = {...state.rallyRelationshipsByTagTypeId, ...byIdObj}
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};