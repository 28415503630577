<template>
<span>
  <span v-if="!this.likeOnly" class="actions" v-click-outside="hideSentiMenu">
    <span v-if="this.likeCountsByShareId(shareId + 'Like') > 0">
      <i class="sentiment-button fa-thumbs-up fas icon like"></i>
      <div class="sentiment-counter like" @click="openSentiments('Like')">({{ this.likeCountsByShareId(shareId + 'Like') }})</div>
    </span>
    <span v-if="this.likeCountsByShareId(shareId + 'Thank') > 0">
      <i class="sentiment-button fa-award fas icon thank"></i>
      <div class="sentiment-counter like" @click="openSentiments('Thank')">({{ this.likeCountsByShareId(shareId + 'Thank') }})</div>
    </span>
    <span v-if="this.likeCountsByShareId(shareId + 'Love') > 0">
      <i class="sentiment-button fa-heart fas icon love"></i>
      <div class="sentiment-counter like" @click="openSentiments('Love')">({{ this.likeCountsByShareId(shareId + 'Love') }})</div>
    </span>
    <div class="action sentiment-button like">
      <span :class="{ 'hide-sentiment-button': shouldDisplayAbove }">
        <i @click="toggleModal($event)"  class="sentiment-button fa-smile fas"></i>
      </span>
        <ul v-if="show_senti_menu" :class="{ 'modal-above': shouldDisplayAbove }" class="additional">
          <li @click="likeClicked('Like')" class="option">
            <i
              v-bind:class="{
                'fa-thumbs-up fas icon like': true,
                blob: like_clicked,
                pulse: like_clicked
              }"
            ></i>
            Like
          </li>
          <li @click="likeClicked('Thank')" class="option">
            <i class="fa-award fas icon thank"></i>
            Thank
          </li>
        </ul>
    </div>
  </span>
  <span v-if="this.likeOnly" class="actions"  >
    <span class="option">
      <i @click="likeClicked('Like')" v-bind:class="{'sentiment-button':true, 'fa-thumbs-up':true, 'icon':true, 'like':true, 'fal':(this.likeCountsByShareId(shareId + 'Like') == 0), 'fas':(this.likeCountsByShareId(shareId + 'Like') > 0)}"></i>
      <div v-if="this.likeCountsByShareId(shareId + 'Like') > 0" class="sentiment-counter like" @click="openSentiments">({{ this.likeCountsByShareId(shareId + 'Like') }})</div>
    </span>
  </span>
  </span>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { router } from "../common";


export default {
  name: "Sentiments",
  props: ["messageId", "shareId", "likeOnly"],
  data() {
    return {
      show_senti_menu: false,
      // show_elip_menu: false,
      like_clicked: false,
      shouldDisplayAbove: false
      //show_like_only: this.likeOnly === true ? true : false
    };
  }, 
  created() {
    this.fetchContentLikeCounts({ shareId: this.shareId});
  },
  computed: {
    ...mapGetters("sentiments", ["likesByShareId", "likeCountsByShareId"])
  },

  methods: {
    ...mapActions("sentiments", ["likeContent", "fetchContentLikes", "fetchContentLikeCounts"]),
    likeClicked(likeType) {
      this.likeContent({ shareId: this.shareId, likeType});
      this.show_senti_menu = false;
      this.shouldDisplayAbove = false;
    },

     hideSentiMenu() {
      this.show_senti_menu = false
    },

    log(message) {
      this.$log.debug(message);
    },

    openSentiments(likeType) {
      var thePath = `/${this.$route.params.rallyId}/sentiments/${this.shareId}?likeType=${likeType}`
      if (this.$route.path != thePath) {
        router.push(thePath)
      }
    },
    toggleModal(event) {
      this.show_senti_menu = !this.show_senti_menu

        // Check if there's enough space below
        const button = event.target;
        const modalHeight = 200; // Let's assume your modal height or calculate it dynamically
        const spaceBelow = window.innerHeight - button.getBoundingClientRect().bottom;
console.log("SHOULD DISPLAY AVOBE")
        this.shouldDisplayAbove = spaceBelow < modalHeight;
console.log(this.shouldDisplayAbove)
    }
  }
};
</script>
