<script>
import { router, findDefaultChannelOfTypeForRally, findDefaultChannelObjOfType } from "../common";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapState("rallies", ["ralliesByParent", "allRalliesByRallyId", "resultCountByRallyId"]),
    ...mapState("memberships", ["membersByRallyId"])
  },
  methods: {
    ...mapActions("memberships", ["fetchMembersByRally"]),
    ...mapActions("rallies", ["fetchRalliesWithInfo", "fetchRalliesByParent"]),
    


  }
};
</script>
