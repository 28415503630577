<template>
  <div class="participate">
      <i  @click="toggleModal" class="fal fa-share-square"/>
    <button v-if="readOnly" :class="[cssClass, 'signup-button']" >
      {{ label }}
    </button>
    <div :class="[{ open: modalStatus }, 'modal']">
      <div class="shadow" @click="toggleModal"></div>
      <div class="body">
        <i class="fal fa-times exit" @click="toggleModal"></i>
        <div class="heading">Assign Someone</div>
        <div class="form-group">
          <select v-model="selectedUserId" v-on:change="onChangeParticipant($event)">
            <option v-for="m in this.membersByRallyId[this.rallyId]" :key="m.attributes['user-id']" :value="m.attributes['user-id']">{{ m.attributes["first-name"] }} {{ m.attributes["last-name"] }}</option>
          </select>
          <select v-model="quantity">
            <option v-for="i in  dropDownMax" :key="i-1" :value="i-1">{{ i-1 }} Spots</option>
          </select>
        </div>
        <div class="actions">
          <button class="secondary" @click="toggleModal">Cancel</button>
          <button class="primary" @click="save">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"; 
import { totalParticipantCount, userInfo } from "../common/user-info"
import ParticipationMixin from "./ParticipationMixin";
import { eventBus } from "../main.js";
export default {
  props: ["content", "channelId", "rallyId", "readOnly"],
  mixins: [ParticipationMixin],
  data: function() {
    return {
      modalStatus: false,
      quantity: 0,
      partId: null,
      state: null,
      originalQuantity: 0,
      selectedUserId: null,
      totalCount: 0,
      participations: []
    };
  },
  computed: {
    ...mapState("participations", ["participationsByShareId"]),
    ...mapState("memberships", [
      "membersByRallyId"
    ]),
    ...mapState("user", ["usersById"]),
    participationsState(){
      return this.participationByShareId[this.shareId]
    },
    maxParts() {
      return this.content.attributes["max-participants"];
    },
    spotsAvailable(){
      return  (this.maxParts - this.partCount)
    },
    dropDownMax(){
      if (this.quantity && this.quantity > 0 &&  (this.quantity > this.spotsAvailable)) {
        return this.quantity + this.spotsAvailable + 1
      } else {
        return (this.maxParts - this.partCount) + 1
      }
    },
    partCount() {
      let t = 0
        this.participations.forEach(p => {
              if (p.attributes['quantity']  && parseInt(p.attributes['quantity']) ){
              t = t + parseInt(p.attributes['quantity'])
            }
        })
      return t
    },
    members() {
      return this.membersByRallyId[this.rallyId];
    }
   // rallyId() {
     // return window.location.href.split("/")[3];
    //}
  },
  created() {
    if (this.myParticipation) {
      this.quantity = this.myParticipation.attributes.quantity;
      this.originalQuantity = this.quantity;
    }
    if (this.membersByRallyId[this.rallyId]){
      this.selectedUserId = this.membersByRallyId[this.rallyId][0].attributes['user-id']
    }
    this.fetchMembersByRally({
        rallyId: this.rallyId,
        status: ["Active"]
    });
    this.getParticipations();
    
  },
  methods: {
    ...mapActions("participations", ["fetchParticipations"]),
    ...mapActions("contents", ["setContentById", "updateContent"]), 
    ...mapActions("memberships", ["fetchMembersByRally"]),
    ...mapActions("participations", [
      "createOrUpdateParticipation",
      "deleteParticipation"
    ]),
    async getParticipations() {
      console.log("loading parts")
      let participationResponse = await this.fetchParticipations({ contextId: this.shareId, pageSize: 100 });
      this.participations = participationResponse.data
      //this.totalCount = totalParticipantCount(participationResponse.data, this.participationById , "assigned"); 
      this.participantsLoaded = true;
    },
    onChangeParticipant(){
      if (this.participationForUser(this.selectedUserId)){
        this.quantity = this.participationForUser(this.selectedUserId).attributes.quantity
      } else {
        this.quantity = 0
      }
    },
    destroy() {
      this.deleteParticipation({ id: this.myParticipation.id });
      var c = this.content.attributes["participant-count"];
      if (!c){
        c = 0
      }
      this.content.attributes["participant-count"] = c - this.originalQuantity;
      this.originalQuantity =  this.content.attributes["participant-count"]
      this.quantity = this.originalQuantity
      this.updateContent({id: this.content.id, content: this.content, rallyId: this.rallyId})
      //this.toggleModal();
      this.quantity = 0
      //this.toggleModal();
      this.save()
    },
    participationForUser(userId) {
      let r = this.participationById[
        this.contentUid + "follower" + userId
      ];
      return r ? r : null 
    },
    save() {
      this.createOrUpdateParticipation({
        id: (this.participationForUser(this.selectedUserId) &&  this.participationForUser(this.selectedUserId).id) ? this.participationForUser(this.selectedUserId).id :  null,
        contentUid: this.contentUid,
        shareId: this.shareId,
        participationStatus: "assigned",
        participantType: "follower",
        quantity: this.quantity,
        channelId: this.channelId,
        contentType: "Event", 
        origination: "opt_in",
        userIdPart: this.selectedUserId
      });
      var c = this.content.attributes["participant-count"];
      if (!c){
        c = 0
      }
      this.content.attributes["participant-count"] = c + (this.quantity - this.originalQuantity);
      this.originalQuantity =  this.content.attributes["participant-count"]
      //this.quantity = this.originalQuantity
      this.updateContent({id: this.content.id, content: this.content, rallyId: this.rallyId, noDateUpdate: true})
      this.toggleModal();
      // }
    },
    toggleModal() {
      this.modalStatus = !this.modalStatus;
      this.getParticipations();
      eventBus.emit('getParticipations')
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
