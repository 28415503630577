<template>
  <div class="body">
    <h2>Change Password</h2>
    <form @submit.prevent="changePassword" ref="loginform">


          <div v-if="error" style="color:red">{{ error }}</div>
          <br/>
          <div class="form-group">
            <label for="password">Password</label>
            <div class="password-input-container">
              <input v-model="password" :type="showPasswordConfirmation ? 'text' : 'password'" id="password" name="password" autocomplete="current-password" class="password-input" @click="scrollTop"/>
              <a href="#" class="toggle-password" @click.prevent="togglePasswordVisibility('passwordConfirmation')">
                {{ showPassword ? 'Hide' : 'Show' }}
              </a>
            </div>
          </div>

          <div class="form-group">
            <label for="password">Confirm Password</label>
            <div class="password-input-container">
              <input v-model="passwordConfirmation" :type="showPasswordConfirmation ? 'text' : 'password'" id="password" name="passwordConfirmation" autocomplete="current-password" class="password-input" @click="scrollTop"/>
              <a href="#" class="toggle-password" @click.prevent="togglePasswordVisibility('passwordConfirmation')">
                {{ showPassword ? 'Hide' : 'Show' }}
              </a>
            </div>
          </div>
      <div class="actions">
        <button class="primary"   type="submit">Save</button>
        <button v-if="resetToken" :disabled="false" class="secondary" type="submit"  @click.prevent="cancel">Cancel</button> 
      </div>
    </form>
  </div>
</template>

<script>
import { router } from "../../common";
import UxMixin from "@/components/UxMixin.vue";
import {mapActions, mapState} from "vuex";
import {userInfo} from "@/common/user-info";

export default {
  name: "ChangePassword",
  props: ["resetToken"],
  data: function () {
    return {
      user_id: null,
      password: '',
      passwordConfirmation: '',
      error: '',
      showPassword: false,
      showPasswordConfirmation: false,
      //status: { loggingIn: false, error: false},
      screenWidth: window.innerWidth
    };
  },

  mixins: [UxMixin],
  computed: {
    ...mapState("user", ["usersById", "status"]),
    currentUser() {
      return this.usersById[this.user_id];
    },
    passwordError() {
      return this.password.length < 8 ||
          !/\d/.test(this.password) ||
          !/[a-z]/.test(this.password) ||
          !/[A-Z]/.test(this.password) ||
          !/[^\w\s]/.test(this.password)
    },
    passwordConfirmationError() {
      return this.passwordConfirmation !== this.password
    },
  },
  created() {
    this.loadPageData();
  },
  methods: {
    ...mapActions("user", ["changeUserPassword", "resetPassword", "clearUsersState"]),     
    async changePassword() {

        if (this.passwordError || this.passwordConfirmationError) {
          this.error = 'Passwords must match and be at least 8 characters long, contain a number, a lowercase letter, an uppercase letter, and a symbol'
          return
        }
        this.error = ''
        if (this.resetToken){
            await this.resetPassword({ token: this.resetToken, password: this.password });
            if (this.status.error){
              alert("Change password ERROR.")
              this.clearUsersState()
            } else {
              alert("Change password SUCCESS!")
              router.push("/login")
            }
        } else {
          this.changeUserPassword({updatedUser: this.currentUser, password: this.password})
          this.$emit('saveComplete');
          this.$store.dispatch('user/flashSuccess', 'Password successfully updated!');
        }
      
    },
    async loadPageData() {
      this.user_id = userInfo()?.user_id;
    },
    togglePasswordVisibility(field) {
      if (field === 'password') {
        this.showPassword = !this.showPassword
      } else if (field === 'passwordConfirmation') {
        this.showPasswordConfirmation = !this.showPasswordConfirmation
      }
    },
    cancel() {
      router.push("/login");
    },
    scrollBack(){
      this.$refs["loginform"].style = ''
    },
    scrollTop(){
      console.log(this.screenWidth)
      if (this.screenWidth < 960) {
      this.$refs["loginform"].style = 'position: fixed; top: 25px; width: 100%'
      }
    },
    scrollToTop () {
      this.$el.scrollTop = 0
    }
  }
}
</script>

<style scoped>
.password-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input {
  flex-grow: 1;
  padding-right: 60px; /* Adjust as needed for space for the toggle link */
}

.toggle-password {
  color: #008295;
  position: absolute;
  right: 10px; /* Adjust as needed for exact positioning */
  cursor: pointer;
  padding: 5px;
  text-decoration: none;
}
</style>