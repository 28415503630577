<template>
  <div>
    <div @click.stop="openModal" style="width: 100%; height: 100%;">
    <a
      :class="menuMode ? '': 'action' "
    >
      <i class="fal fa-share-alt"></i><span v-if="menuMode" class="">Share</span>
    </a>
      </div>

    <div :class="[{ open: modalStatus }, 'modal', 'sharing-modal']"> 
      <div class="shadow" @click="closeModal"></div>
      <div class="body">
        <i class="fal fa-times exit" @click="closeModal"></i>
        <div v-if="isLoading">
          <div class="small-loading">
            <i class="fas fa-spinner fa-spin"></i>
            {{ loadingMessage }}
          </div>
        </div>
        <div v-if="!isLoading && sharesStatus == null" class="loaded">
          <div v-if="step == 1">
            <div>
              <div class="heading" style="font-size: 1.2rem">Where would you like to {{ publishingMode }} this? </div>
              <div v-if="isPublishingMode && !isLoading" class="form-group stacked-labels checkboxes rallies">
                <label
                    v-for="category in categories"
                    :key="category.id"
                    > <input
                      :name="categories"
                      type="checkbox"
                      :value="category.id"
                      @change="toggleAllRalliesUnderCategory(category)"
                      :checked="checkedCategories[category.id]"
                      :disabled="isCategoryDisabled(category.id)"
                    />
                    <div class="square"></div>
                  {{ category.attributes['name'] }} <span style="color: lightgrey">({{ getCheckedRalliesCount(category) }} of {{ getCategoryRalliesCount(category) }})</span>
                    <span @click.stop>
                      <!-- Show the collapse icon (collapse.svg) if the category is expanded, and the expand icon (expand.svg) if collapsed -->
                      <img
                          v-show="expandedCategories[category.id]"
                          src="/collapse.svg"
                          alt="Collapse"
                          style="background-position: center center;
                                  background-repeat: no-repeat;
                                  background-size: 20px 20px;
                                  height: 20px;
                                  display: inline-block;
                                  float: right;
                                  width: 20px;"
                          @click.prevent="toggleCategory(category)"
                      />
                      <img
                          v-show="!expandedCategories[category.id]"
                          src="/expand.svg"
                          alt="Expand"
                          style="background-position: center center;
                                  background-repeat: no-repeat;
                                  background-size: 20px 20px;
                                  height: 20px;
                                  display: inline-block;
                                  float: right;
                                  width: 20px;"
                          @click.prevent="toggleCategory(category)"
                      />

                    </span>
                  <div v-if="expandedCategories[category.id]" style="margin-top: 5px;">
                      <label
                          v-for="rally in fetchedRalliesByCategory[category.id]"
                          :key="rally.id"
                      >
                        <div v-if="rally && !originatingShare(rally)" :style="{ color: computedBackgroundColor(rally) }">
                        <input
                            :name="computedRallies(rally)"
                            :type="computedType(rally)"
                            v-on:change="doCheck(rally.id, category.id)"
                            :value="rally.id"
                            :checked="isSelected(rally)"
                            :disabled="disabled(rally)"
                        />
                        <div class="square"></div>
                        {{ rally.attributes.title }}
                        <span class="key" v-if="originatingRally(rally)">
                          <i class="fa fa-home" style="float:right; color:black; margin-top: 5px; margin-right: 10px;" aria-hidden="true"></i>
                        </span>
                        <span class="key" v-if="someoneElse(rally)">
                          <i class="fa fa-user-slash" style="float:right; color:black; margin-top: 5px; margin-right: 10px;" aria-hidden="true"></i>
                        </span>
                        <span class="key" v-if="!someoneElse(rally) && !originatingRally(rally) && !originatingSharedFrom(rally)">
                          <i class="fa fa-asterisk" style="float:right; color:black; margin-top: 5px; margin-right: 10px;" aria-hidden="true"></i>
                        </span>
                        </div>

                        <div v-else>
                          <input
                              name="rallies"
                              type="hidden"
                              :value="rally.id"
                              :disabled="disabled(rally)"
                          />
                        </div>
                     </label>
                    </div>
                </label>
                </div>
              <div v-else class="form-group stacked-labels checkboxes rallies">
                <div v-if="!isLoading">
                <label
                  v-for="rally in allRalliesExceptThis"
                  :key="rally.id"
                >
                <div v-if="rally && !originatingShare(rally)" :style="{ color: computedBackgroundColor(rally) }">
                  <input
                   :name="computedRallies(rally)"
                   :type="computedType(rally)"
                   v-on:change="doCheck(rally.id, '')"
                   :value="rally.id"
                   :checked="isSelected(rally)"
                   :disabled="disabled(rally)"
                  />
                  <div class="square"></div>
                  {{ rally.attributes.title }}
                  <span class="key" v-if="originatingRally(rally)">
                    <i class="fa fa-home" style="float:right; color:black; margin-top: 5px; margin-right: 10px;" aria-hidden="true"></i>
                  </span>
                  <span class="key" v-if="someoneElse(rally)">
                    <i class="fa fa-user-slash" style="float:right; color:black; margin-top: 5px; margin-right: 10px;" aria-hidden="true"></i>
                  </span>
                  <span class="key" v-if="!someoneElse(rally) && !originatingRally(rally) && !originatingSharedFrom(rally)">
                    <i class="fa fa-asterisk" style="float:right; color:black; margin-top: 5px; margin-right: 10px;" aria-hidden="true"></i>
                  </span>
                  </div>
                  <div v-else>
                    <input
                        name="rallies"
                        type="hidden"
                        :value="rally.id"
                        :disabled="disabled(rally)"
                    />
                  </div>
                </label>
                <!--<infinite-loading ref="infLoad2" @infinite="infiniteHandler">
                  <span slot="no-more"> </span>
                  <span slot="no-results"> </span>
                </infinite-loading>-->
                </div>
              </div>
              <div class="share-modal">
                <i class="fa fa-home">
                  = Originating rally
                </i>
              </div>
              <div class="share-modal">
                <i class="fa fa-user-slash">
                  = {{ publishingModed }} by someone else
                </i>
              </div>
              <div class="share-modal">
                <i class="fa fa-asterisk">
                  = {{ publishingModed }} from another rally
                </i>
              </div>
            </div>
            <div class="actions">
              <div class="left">
                <button class="secondary" @click="closeModal">Cancel</button>
              </div>
              <div class="right">
                  <!--<button class="primary" @click="next">Next</button>-->
                  <button class="primary" @click="next" :disabled="checkingRally">Next</button>
              </div>
            </div>
          </div>
          <div v-if="step == 2">
            <div class="heading">Add a message if you would like</div>
            <div class="form-group">
              <textarea v-model="shareMessage"
                class="share-message"
                rows="5"
              />
            </div>
            <div class="actions">
              <div class="left">
                <button class="secondary" @click="back">Back</button>
              </div>
              <div class="right">
                <button class="primary" @click="share">{{ publishingMode }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import RallyPaginationMixin from "../components/RallyPaginationMixin";
import {userInfo} from "@/common/user-info";
import { reactive } from 'vue';

export default {
    mixins: [RallyPaginationMixin],
    props: ['content', 'rallyId', 'channelId', 'contentType', 'menuMode'],
    data: function() {
      return {
        checkingRally: false,
        modalStatus: false,
        isLoading: true,
        loadingMessage: 'Loading...',
        //createSuccess: null, 
        step: 1,
        cbChanges:{},
        page: 1,
        shareMessage: '',
        categorizedRalliesCache: {},
        channels: [],
        childRallies: [],
        childRallyRelationships: [],
        fetchedRalliesByCategory: {},
        expandedCategories: {},
        checkedCategories: {},
        categories: [],
        categoriesByRallyId: {},
        rallyRelationshipsByTagTypeId: {},
        rallyRelationshipsByCategoryId: {},
        sharesByContentId: [],
        userMemberships: [],
        userMembershipRallies: []
      };
    },
    created () {
     this.clearRallyState();
      if (!this.cbChanges){
        this.cbChanges = {}
      }
    },
   watch: {
    sharesStatus: function(newval, oldval) {
      if (newval == 'success'){
         this.$store.dispatch('user/flashSuccess', 'Sharing successful!');
         this.modalStatus = false;
      }
    }

  },
    computed: {
      ...mapState("channels", ["channelsById"]),
      ...mapState("shares", ["sharesStatusByContentId"]),
      allRalliesExceptThis(){
        if(this.isPublishingMode) {
          return this.childRallies
        } else {
          return this.userMembershipRallies.filter((rally) => {
            const rallyId = parseInt(rally.id); // Convert to integer
            return rallyId !== parseInt(this.rallyId);
          });
        }
      },
      contentShares(){
        return this.sharesByContentId
      },
      isManagingCommunity() {
        return (this.thisRally.rally.attributes['plan-type'] == 'Premium' ||
            this.thisRally.rally.attributes['plan-type'] == 'Pro' ||
            this.thisRally.rally.attributes['plan-type'] == 'Legacy' ||
            this.thisRally.rally.attributes['plan-type'] == 'Interim'
        )
      },
      isPublishingMode() {
        return (this.thisRally.rally.attributes['editable'] == true && this.isManagingCommunity)
      },
      thisRally() {
        return this.allRalliesByRallyId[this.rallyId]
      },
      publishingMode() {
        if(this.isPublishingMode) {
          return 'publish'
        } else {
          return 'share'
        }
      },
      publishingModed() {
        if(this.isPublishingMode) {
          return 'Published'
        } else {
          return 'Shared'
        }
      },
      sharesStatus(){
        if (this.sharesStatusByContentId && this.sharesStatusByContentId[parseInt(this.content.id)]){
          return this.sharesStatusByContentId[parseInt(this.content.id)]
        } else {
          return null
        }
      },

    },

    methods: {
      ...mapActions("channels_v2", ["fetchChannels"]),
      ...mapActions("memberships_v2", ["fetchMemberships"]),
      ...mapActions("shares_v2", ["fetchSharesByContentId"]),
      ...mapActions("shares", ["clearContentShareState", "shareContent", "setSharesStatusByContentId"]),
      ...mapActions("rallies", ["clearRallyState", "fetchRalliesWithInfo", "fetchRalliesByParent"]),
      ...mapActions("rallies_v2", ["fetchRallies", "fetchRallyRelationshipsByCategory", "fetchRalliesByParentId"]),
      ...mapActions("tags_v2", ["fetchCategoriesByRally", "fetchRallyRelationshipsByTagTypeId"]),
      setCategory(index, val){
        this.checkedCategories[index] = val 
      },
      setCbChanges(index, val){
        this.cbChanges[index] = val 
      },
      deleteCbChanges(index, val){
        this.cbChanges.splice(index, 1);
      },
      setExpandedCategories(index, val){
        this.expandedCategories[index] = val 
      },
      async doCheck(rid, categoryId){
        this.checkingRally = true;
        if (this.cbChanges[rid]){
          this.cbChanges[rid] = null
        } else {
          this.cbChanges[rid] = true
          if (this.rallyChannelsByRallyId && !this.rallyChannelsByRallyId[rid]){
            //await this.fetchRalliesWithInfo({rallyId:rid})
          }
        }
        if(this.isPublishingMode) {
          this.checkCategories(categoryId)
        }
        this.checkingRally = false;
      },
      back() {
        this.step -= 1;
      },
      checkCategories(categoryId) {
        this.loadingMessage = 'Checking categories...';
        const rallies = this.fetchedRalliesByCategory[categoryId];
        //console.log("Rallies")
        //console.log(rallies)
        let allChecked = true
        rallies.forEach((rally) => {
          this.loadingMessage = 'Processing rally ' + rally.attributes.title;

          if(!this.cbChanges[rally.id]) {
            allChecked = false
          }
        });
        if (allChecked) {
          this.setCategory(categoryId, true)
          //this.$set(this.checkedCategories, categoryId, true);
        } else {
          this.setCategory(categoryId, false)
          //this.$set(this.checkedCategories, categoryId, false);
        }
      },
      closeModal() {
        if (this.allRalliesExceptThis){
          this.allRalliesExceptThis.forEach(r => {
            r.shareSelected = null
          });
        }
        this.step = 1
        this.cbChanges = {}
        this.shareMessage = ""
        this.clearRallyState();
        this.clearContentShareState();
        //if (!this.cbChanges){
        //  this.cbChanges = {}
        //}
        this.isLoading = true;
        this.modalStatus = false;
        this.fetchedRalliesByCategory[0] = []
        this.checkCategories(0)
      },
      computedBackgroundColor(rally) {
        if (this.originatingRally(rally) || this.someoneElse(rally)) {
          return "#BDBDBD"
        } else if (!this.canPublishToChannel(rally)) {
          return "lightgrey"
        }
      },
      computedRallies(rally) {
        if (this.originatingRally(rally) || this.someoneElse(rally)) {
          return "DONTUPDATE"
        } else {
          return "rallies"
        }
      },
      computedType(rally) {
        if (this.originatingShare(rally)) {
          return "checkbox"
        } else {
          return "checkbox"
        }
      },
      isCategorySelected(categoryId){
        return this.selectedCategoryIds.includes(categoryId);
      },
      isCategoryDisabled(categoryId) {
        const rallies = this.fetchedRalliesByCategory[categoryId];
        return rallies && rallies.every((rally) => this.disabled(rally));
      },
      isSelected(rally) {
        //console.log('Checking isSelected for rally:', rally.id, this.cbChanges);
        return this.cbChanges[rally.id] === true; // Simplified for clarity
      },
      disabled(rally) {
        //console.log("Rally: " + rally.attributes.title)
        //console.log("Originating Share: " + this.originatingShare(rally))
        //console.log("Someone Else: " + this.someoneElse(rally))
        //console.log("Originating Rally: " + this.originatingRally(rally))
        //console.log("Originating Shared From: " + this.originatingSharedFrom(rally))
        //console.log("Can Publish To Channel: " + this.canPublishToChannel(rally))
        //console.log("Shared By Me: " + this.sharedByMe(rally))

        return this.originatingShare(rally) ||
            this.someoneElse(rally) ||
            this.originatingRally(rally) ||
            !this.originatingSharedFrom(rally)
            || !this.canPublishToChannel(rally) ||
            this.sharedByMe(rally);
      },
      toggleAllRalliesUnderCategory(category) {
        const categoryId = category.id;
        const rallies = this.fetchedRalliesByCategory[categoryId];
        //console.log("Toggled rallies?")
        //console.log(rallies)
        if (this.isCategoryDisabled(categoryId)) {
          console.log('category disabled')
          return; // If category is disabled, do nothing
        }

        const checked = !this.checkedCategories[categoryId];
        //console.log("Checked")
        //console.log(checked)
        //this.$set(this.checkedCategories, categoryId, checked);
        this.setCategory(categoryId, checked)

        rallies.forEach((rally) => {
          if (!this.disabled(rally)) {
            if (checked) {
              this.setCbChanges(rally.id, true)
              //this.$set(this.cbChanges, rally.id, true);  // Select rally
            } else {
              this.deleteCbChanges(rally.id)  // Deselect rally
              //this.$delete(this.cbChanges, rally.id);     
            }
          }
        });

      },

      next() {
        this.step += 1;
      },
      filterUncategorizedRallies() {
        this.loadingMessage = 'Filtering uncategorized rallies...';
        const uncategorizedRallies = this.childRallies;
        const allCategoryRallies = Object.values(this.fetchedRalliesByCategory)
            .flatMap((rallies) => rallies.map((rally) => rally.id.toString()));

        if (!uncategorizedRallies) {
          return []
        }
        return uncategorizedRallies.filter((rally) => {
          return !allCategoryRallies.includes(rally.id.toString()) && rally?.attributes['status'] != 'Archived';
        });
      },

      async openModal() {
        try {
          this.modalStatus = true;
          this.cbChanges = {};
          await this.setSharesStatusByContentId({id: this.content.id, status: null})
          this.loadingMessage = 'Fetching existing share locations...';
          this.sharesByContentId = await this.fetchSharesByContentId({contentId: this.content.id, contextRallyId: this.rallyId, contextChannelId: this.channelId})
          //console.log("Shares By Content Id")
          //console.log(this.sharesByContentId)
          //console.log('cb changes pre')
          //console.log(this.cbChanges)
          //this.cbChanges = this.sharesByContentId.map(item => item.attributes['context-rally-id']);
          this.cbChanges = this.sharesByContentId.reduce((map, obj) => {
            const key = obj.attributes['context-rally-id'];
            if (key !== undefined) {
              map[key] = true; // Store the entire object as the value
            }
            return map;
          }, {});

          //console.log('cb changes post')
          //console.log(this.cbChanges)
          if (this.isPublishingMode) {
            this.loadingMessage = 'Fetching categories...';
            this.categories = await this.fetchCategoriesByRally({rallyId: this.rallyId})
            //console.log("FETCHED categories")
            //console.log(this.categories)
            this.loadingMessage = 'Fetching rallies...';

            this.childRallies = await this.fetchRalliesByParentId({
              rallyId: this.rallyId,
              page: 1,
              pageSize: 300,
              include: ''
            })

            //this.categories = this.categoriesByRallyId[this.rallyId]

            for (const category of this.categories) {
              this.loadingMessage = `Loading ${category.attributes.name}...`;
              await this.fetchRalliesForCategory(category);
            }
            this.categories.push({id: 0, attributes: {name: 'Uncategorized'}})
            //await new Promise(resolve => setTimeout(resolve, 2000));
            this.fetchedRalliesByCategory[0] = this.filterUncategorizedRallies();
            this.checkCategories(0)

            let channelType = this.channelsById[this.channelId].attributes['type']
            this.loadingMessage = 'Fetching ' + channelType + ' channels...';
            if (this.childRallies.length > 0) {
              this.channels = await this.fetchChannels({rallyIds: this.childRallies.map(r => r.id).join(','),
                type: channelType,
                pageSize: this.childRallies.length})
            }
          }
          else {
            this.userMemberships = await this.fetchMemberships({userIds: userInfo().id, include: 'rally', pageSize: 300})
            this.userMembershipRallies = await this.fetchRallies({rallyIds: this.userMemberships.map(r => r.attributes["rally-id"]), pageSize: this.userMemberships.length})
            this.channels = await this.fetchChannels({rallyIds: this.userMemberships.map(r => r.attributes['rally-id']).join(','),
              type: this.channelsById[this.channelId].attributes['type'],
              pageSize: this.userMemberships.length})

          }
          console.log("CHANNESL")
          console.log(this.channels)
        } catch (e)
        {
         console.log(e) 
        }
        this.isLoading = false;
      },
      originatingShare(rally) {
        // --- This is the share record for the current rally
        // --- This may or may not be the same share record as tha of the originating rally
        // --- i.e. the user may be sharing from a share
        return ( rally.id == this.rallyId );
      },
      originatingSharedFrom(rally) {
        // --- This is the share record for the current rally
        // --- This may or may not be the same share record as tha of the originating rally
        // --- i.e. the user may be sharing from a share
        const contentShare = this.findContentShareByContextRallyId(rally.id)
        const currentRallyShare = this.findContentShareByContextRallyId(this.rallyId)
        let ret = true;
        if (contentShare) {
          ret = ( contentShare && contentShare.attributes['shared-from-id'] == currentRallyShare.id );
        }
        return ret;
      },
      originatingRally(rally) {
        // --- This is the rally where the content was originally posted
        const contentShare = this.findContentShareByContextRallyId(rally.id)
        //console.log("contentShare")
        //console.log(contentShare)
        return this.originatingRallyShare(contentShare)
      },
      originatingRallyShare(contentShare) {
        // --- This is the rally where the content was originally posted
        return (contentShare && contentShare.attributes['share-type'] === 'original')
      },
      fetchRalliesForCategory(category) {
        const categoryId = category.id;
        if (!this.fetchedRalliesByCategory[categoryId]) {
          // Set isLoading to true to show the spinner
          this.isLoading = true;

          // Return a Promise that resolves when rallies are fetched
          return this.ralliesByCategory(category)
              .then((rallies) => {
                //console.log("Fetched rallies for category:", categoryId, rallies);
                this.fetchedRalliesByCategory[categoryId] = rallies;
                //console.log("Fetched rallies for category:", categoryId, this.fetchedRalliesByCategory[categoryId]);
                this.checkCategories(categoryId)
                // Set isLoading to false after data is fetched
                this.isLoading = false;
                return rallies; // Resolve the Promise with fetched rallies
              })
              .catch((error) => {
                this.isLoading = false;
                console.error(error);
                return Promise.reject(error); // Reject the Promise with the error
              });
        } else {
          // If rallies are already fetched, return a Promise that resolves with the cached rallies
          return Promise.resolve(this.fetchedRalliesByCategory[categoryId]);
        }
      },
      async ralliesByCategory(category) {
        const categoryId = category.id;

        // Check if the categorized rallies are already cached
        if (this.categorizedRalliesCache[categoryId]) {
          return this.categorizedRalliesCache[categoryId];
        }
        let categoryRallies = []
        if(category && category.id) {
          this.rallyRelationshipsByTagTypeId[category.id] = await this.fetchRallyRelationshipsByTagTypeId({tagTypeId: category.id})
          let rallyRelationshipIds = this.rallyRelationshipsByTagTypeId[category.id]
          //console.log("RALLY RELATINSHIPS IDS")
          //console.log(rallyRelationshipIds)
          //console.log("--------------------------")
          const taggableIds = rallyRelationshipIds.map((item) => item.attributes['taggable-id']).join(',');
          //console.log("TAGGABLE IDS")
          //console.log(taggableIds)
          if (taggableIds && taggableIds.length > 0) {
            this.rallyRelationshipsByCategoryId[category.id] = await this.fetchRallyRelationshipsByCategory({categoryId: category.id, rallyRelationshipIds: taggableIds})
            //console.log("RALLY RELATIONSHIPSByCategoryId")
            //console.log(this.rallyRelationshipsByCategoryId[category.id])
            let rallyRelationships = this.rallyRelationshipsByCategoryId[category.id]
            //console.log("RALLY RELATIONSHIPS")
            //console.log(rallyRelationships)
            let rallyIds = rallyRelationships.map((item) => item.attributes['right-rally-id']).join(',')
            //console.log("RALLY IDS")
            //console.log(rallyIds)
            categoryRallies = await this.fetchRallies({rallyIds: rallyIds, pageSize: rallyIds.length})
            //rallyRelationships.forEach((relationship) => {
            //  console.log("PUSHING..." + relationship.attributes['right-rally-id'])
            //  categoryRallies.push(this.allRalliesByRallyId[relationship.attributes['right-rally-id']])
            //});
          }
        }
        //console.log("Category Rallies")
        //console.log(categoryRallies)
        //console.log(this.rallyRelationshipsByCategoryId[categoryId])
        this.categorizedRalliesCache[categoryId] = categoryRallies
        return categoryRallies
      },
      canPublishToChannel(rally){
        //return false;
        return rally?.attributes?.editable || rally?.attributes?.writable
        /*if (this.rallyChannelsByRallyId && this.rallyChannelsByRallyId[parseInt(rally.id)]) {
          var chanObj = findDefaultChannelObjOfType(this.contentType, this.rallyChannelsByRallyId[parseInt(rally.id)], this.content.attributes['type']);
          if (chanObj){
            return chanObj.attributes['writable']
          }
        }
        return false*/
      },
      selectedRallies(){
        var sr = []
        sr.push[this.rallyId + ""]
        if (this.allRalliesExceptThis){
          this.allRalliesExceptThis.forEach(r => {
            if (this.cbChanges[r.id] && !this.someoneElse(r) && !this.originatingRally(r) && this.originatingSharedFrom(r)) {
              sr.push(r.id + "")
            }
          });
        }
        console.log("Selected rallies")
        console.log(sr)
        return sr
      },
      selectedChannels(){
        var sc = []
        if (this.allRalliesExceptThis){
          this.allRalliesExceptThis.forEach(r => {
            if (this.cbChanges[r.id] && !this.someoneElse(r) && !this.originatingRally(r) && this.originatingSharedFrom(r)) {
              if (this.channels) {
                var chanObj = this.channels.find(c => c.attributes['rally-id'] == r.id)
                  //var chanObj = findDefaultChannelObjOfType(this.contentType, this.rallyChannelsByRallyId[parseInt(r.id)], this.content.attributes['type']);
                if (chanObj){
                  sc.push(chanObj.id + "")
                }
              }
            }
          })
        }
        return sc
      },
      share() {
       this.isLoading = true
      // this.createSuccess = 'pending'
       this.setSharesStatusByContentId({id: this.content.id, status: "pending"})
       const contentShare = this.findContentShareByContextRallyId(this.rallyId)


        let shareType = 'shared'
        if(this.isPublishingMode){
          shareType = 'published'
        }
        this.shareContent({ contentUid: this.content.id,
         channelIds: this.selectedChannels(),
         rallyIds: this.selectedRallies(),
         status: 1,
         owner : false,
         shareMessage: this.shareMessage,
         shareType: shareType,
         sharedFromId: contentShare.id})
       this.setSharesStatusByContentId({id: this.content.id, status: "success"})
       this.closeModal();
      },
      someoneElse(rally) {
        const contentShare = this.findContentShareByContextRallyId(rally.id)
        return (this.someoneElseShare(contentShare) && this.isSelected(rally))
      },
      someoneElseShare(contentShare) {
        return (contentShare && contentShare.attributes['owner'] === false
            && contentShare.attributes['creator-id'] != userInfo().user_id)
      },
      sharedByMe(rally){
        const contentShare = this.findContentShareByContextRallyId(rally.id)
        return (contentShare && contentShare.attributes['owner'] === false
            && contentShare.attributes['creator-id'] == userInfo().user_id)
      },
      findContentShareByContextRallyId(contextRallyId) {
        // Convert contextRallyId to numeric (integer) if it's a string
        const numericContextRallyId = parseInt(contextRallyId, 10);

        // Find the object with matching 'context-rally-id'
        //console.log("Finding content share by context rally ID:", numericContextRallyId);
        //console.log(this.contentShares)
        return this.contentShares.find((contentShare) => {
          const contentShareContextRallyId = contentShare.attributes['context-rally-id'];
          return contentShareContextRallyId === numericContextRallyId;
        });
      },
      checkAllRalliesUnderCategory(category) {
        const categoryId = category.id;
        const checked = this.checkedCategories[categoryId] || 'false';
        const rallies = this.fetchedRalliesByCategory[categoryId];

        // Check or uncheck all rally checkboxes under the category based on the checked state
        rallies.forEach((rally) => {
          this.setCbChanges(rally.id, checked)
         //this.$set(this.cbChanges, rally.id, checked);
        });
      },
      toggleCategory(category) {
        const categoryId = category.id;
        this.setExpandedCategories(categoryId, !this.expandedCategories[categoryId])
        //this.$set(this.expandedCategories, categoryId, !this.expandedCategories[categoryId]);
      },
      getCheckedRalliesCount(category) {
        const rallies = this.fetchedRalliesByCategory[category.id];
        if (!rallies || rallies[0] === undefined) return 0;
        return rallies.filter((rally) => this.selectedRallies().includes(rally.id.toString())).length;
      },
      getCategoryRalliesCount(category) {
        const rallies = this.fetchedRalliesByCategory[category.id];
        return rallies ? rallies.length : 0;
      },
    }
  }
</script>
