<script>
  import {  Filesystem, FilesystemDirectory, FilesystemEncoding, Share, Capacitor } from "@capacitor/core";
  import { timezoneMapping, railsTzToMomentTz } from "../common";
  import moment from "moment-timezone";
  import { userInfo } from "../common/user-info"

  export default {

    computed : {
      defaultTimezone(){
        const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var tz = railsTzToMomentTz(userInfo().timezone) ? railsTzToMomentTz(userInfo().timezone) : timezoneName
        return tz
      }
    },
    methods: {
      doFormatTime(time) {
        return moment(time).format("h:mm a");
      },
      doFormatDate(time) {
        return moment(time).format("MMMM, DD YYYY");
      },
      doFormatDateSpecialEvent(time) {
        return time
      },
      formatDate(selectedEvent) { 
      if (selectedEvent.allDay == 1){
        if (moment(selectedEvent.start_time).format("MM/DD/YYYY") ==  moment(selectedEvent.end_time).subtract(1, "day").format("MM/DD/YYYY")){
          return selectedEvent.all_day_start_date   
        }else if (selectedEvent.all_day_end_date != selectedEvent.all_day_start_date){
          return selectedEvent.all_day_start_date + " - " + selectedEvent.all_day_end_date
        } else{
          return selectedEvent.all_day_start_date  
        }
      } else {
        var tz = railsTzToMomentTz(userInfo().timezone) ? railsTzToMomentTz(userInfo().timezone) : selectedEvent.timezone
        var startDate = moment(selectedEvent.start_time)
          .tz(selectedEvent.timezone)
          .format("MM/DD/YYYY");
        var endDate = moment(selectedEvent.end_time)
          .tz(selectedEvent.timezone)
          .format("MM/DD/YYYY");
        var endFormat = "hh:mm a";
        if (startDate != endDate) {
          endFormat = "MM/DD/YYYY hh:mm a";
        }
        return (
          moment(selectedEvent.start_time)
            .tz(selectedEvent.timezone)
            .format("MM/DD/YYYY hh:mm a") +
          "-" +
          moment(selectedEvent.end_time)
            .tz(selectedEvent.timezone)
            .format(endFormat)
        );
      }
    },

    }
  };
</script>
