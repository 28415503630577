<script>
  import { Filesystem, FilesystemDirectory, FilesystemEncoding, Share, Capacitor } from "@capacitor/core";
  import moment from "moment";
  import axios from "axios";

  export default {
    methods: {

      formatDate(thedate) {
         return moment(thedate).format("MMMM DD, YYYY hh:MM a")
      },
      async saveFile(base64Data) {
        try {
          var result = await Filesystem.writeFile({
            path: "Downloads/yoda.png",
            data: base64Data,
            directory: FilesystemDirectory.directory,
            encoding: FilesystemEncoding.UTF8
          });
        } catch (e) {
          console.error("Unable to write file", e);
        }
      },

      log(message) {
        this.$log.debug(message);
      }
    }
  };
</script>
