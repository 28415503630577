<template>
  <article>
    <div class="wrapper">
      <div @click.prevent="openLink(content.attributes.url)" style="margin-top: 40px;">
        <img class="avatar" :src="content.attributes['image-url']" />
        <div class="title">{{ content.attributes.title }}</div>
        <div class="url">{{ content.attributes.url }}</div>
        <div class="description">{{ content.attributes.description }}</div>
      </div>
      <!-- Lightbox for displaying the video -->
      <div v-if="showModal" class="lightbox-wrapper">
        <button class="lightbox-close" @click="closeModal">
          <i class="fal fa-times" aria-hidden="true"></i>
        </button>
        <article class="content details lightbox photo" style="height: 700px;">
          <iframe
              :src="`https://www.youtube.com/embed/${currentVideoId}`"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen>
          </iframe>
        </article>
      </div>
      <footer>
        <div class="left" style="width: 75%;">
          <div class="author-name">
            {{ byLine }}
          </div>
          <div class="created-at">
            {{ when }}
          </div>
        </div>
        <ContentElipsisMenu :content="content" :rallyId="this.rallyId" :channelId="this.channelId" contentType="Links" can-share="true"/>
      </footer>
    </div>
  </article>
</template>

<script>
import ContentMixin from "../../ContentMixin";
import { avaUrl } from "../../../common"; 

import { Browser } from "@capacitor/browser";
export default {
  name: "Link",
  mixins: [
    ContentMixin,
  ],
  data() {
    return {
      showElipsis: false,
      showModal: false,
      currentVideoId: null
    };
  },
  props: {
    channel: Object,
    rally: Object,
    content: Object
  },
  methods: {
    hideActions() {
      this.showElipsis = false;
    },
    openLink(url) {
      if (url.includes('youtube.com') && url.includes('watch')) {
        // Extract the YouTube video ID from the URL.
        const videoId = new URL(url).searchParams.get('v');
        this.openVideo(videoId);
      } else {
        window.open(url, '_blank'); // Open external URL in a new tab.
      }
    },
    openVideo(videoId) {
      this.currentVideoId = videoId;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.currentVideoId = null;
    }
  }
};
</script>


