<template>
  <div>
    <RallyNav :rallyId="this.rallyId" :channelSlug="channelSlug" />
    <main :class="mainClass">
      <ToolBar back="navUp" v-on:perform="perform" />
      <section class="content-area">
        <component
          v-on:perform="perform"
          :class="contentClasses"
          :is="componentName"
          :channelId="channelId"
          :contextId="contextId"
          :rallyId="rallyId"
          :content="content"
        />
      </section>
    </main>
  </div>
</template>

<script>
  
  import { mapState, mapActions, mapGetters } from "vuex";
  import {
    router,
    slugToTypeObjectMap,
    findDefaultChannelOfType,
    buildListOfValues,
    kebabCase
  } from "../common";

  export default {
    data() {
      return {
        tempKey: "",
        newContent: {attributes: {"title": ""}},
        channelId: "",
        contextId: "",
        contextType: "",
        page: 1,
        parentContextIds: [],
        parentContextTypes: [],
        rallyId: null,
      };
    },
    computed: {
      ...mapState("rallies", ["rallyChannelsByRallyId"]),
      ...mapState("contents", ["contentById"]),
      content() {
        if (this.tempKey && this.contentById[this.tempKey]){
          return this.contentById[this.tempKey];
        } else {
          return this.newContent
        }
      },

      channelSlug() {
        // Used in place of channel.type.
        return this.$route.name;
      },
      componentName() {
        return `${this.slugMap.componentName}Form`;
      },
      contentClasses() {
        const contentType = this.slugMap.contentType.toLowerCase();
        return `content as-form ${contentType}`;
      },
      mainClass() {
        return `${kebabCase(this.channelSlug)} new`;
      },
      slugMap() {
        return slugToTypeObjectMap()[this.channelSlug];
      },
      unsaved() {
        return this.content.id == null;
      }
    },
    created() {
      this.rallyId = this.$route.params.rallyId;
      var chanId = findDefaultChannelOfType(
        "Calendar", //slugToTypeObjectMap()[this.channelSlug].channelType,
        this.rallyChannelsByRallyId[this.rallyId]
      );
      this.channelId = parseInt(chanId);
      this.contextId = parseInt(chanId);
      this.tempKey = "TK" + Math.floor(Math.random() * 10000);
      this.content.attributes.title = ""
      this.content.attributes.body = "";
      this.content.tempKey = this.tempKey
      this.content.attributes.contentType = "Events"
      this.content.editMode = true
      this.createContents({  title:"", contextType: "Channel", type: "CalendarEvent", contentType: "Event", rallyId: this.rallyId, contextId: this.channelId, contextChannelId: this.channelId, contextRallyId:  this.rallyId, status:0, tempKey: this.tempKey, });
    },
    methods: {
    ...mapActions("contents", ["createContents", "updateContent"]),
      cancel() {
        this.navUp();
      },
      navUp() {
        const path = `/${this.rallyId}/${this.channelSlug}`
        router.push(path);
      },
      perform(method, params) {
        this[method](params);
      },
      log(message) {
        this.$log.debug(message);
      },
      save() {
          //if (this.contentType == 'Event') {
              this.updateContent({id: this.content.id, status: 1, content: this.content, rallyId: this.rallyId})
              this.navUp()
              //Started PUT "/38720/events/19025702" for 127.0.0.1 at 2022-01-30 17:58:38 -0600
              //Processing by ContentsController#update as HTML
              //Parameters: {"utf8"=>"✓", "authenticity_token"=>"V7As/m02qwUxLgbbCuvYfl3lQMk/33AqkyEIcUW0KCaY9CzpfNsmYMHBooieY4erjqflvB/fkMDtfL1AMAs5DA==", "content"=>{"status"=>"1", "timezone"=>"Central Time (US & Canada)", "title"=>"JT Web Event Jan 30", "location"=>"my house", "online_location"=>"https://zoom.us/11111", "online_location_password"=>"[FILTERED]", "description"=>"the description", "color"=>"#1c8291", "rsvp"=>"1", "all_day"=>"0", "from_date"=>"01/30/2022", "from_time"=>"05:00 PM", "to_time"=>"06:00 PM", "to_date"=>"01/30/2022", "repeat_frequency"=>"no_repeat"}, "override_url"=>"/38720/events", "commit"=>"Save", "rally_id"=>"38720", "channel_id"=>"events", "id"=>"19025702"}
              /* var newContent = this.contentById[this.tempKey];
               params2['contentType'] = 'Event'
               params2['status'] = this.content.attributes['status']
               params2['timezone'] = this.content.attributes['timezone']
               params2['location'] = this.content.attributes['location']
               params2['online_location'] = this.content.attributes['online_location']
               params2['privacy'] = "1"
               params2['title'] = this.content.attributes['title']
               params2['all_day'] = this.content.attributes['all_day']
               params2['from_date'] = this.content.attributes['from_date']
               params2['to_date'] = this.content.attributes['to_date']
               params2['from_time'] = this.content.attributes['from_time']
               params2['to_time'] = this.content.attributes['to_time']
               params2['repeat_frequency'] = this.content.attributes['to_time']*/
            //}
        } 
    }
  };
</script>
