<template>
  <article class="as-form">
    <form>
      <div class="toolbar">
        <div class="left">
          <a @click="cancelEvent()" class="back">
            <i class="fal fa-chevron-left icon"></i>
            Cancel
          </a>
        </div>
        <div class="right">
          <button
            @click.prevent="saveAttachmentsThenCreate()"
            class="primary"
            type="submit"
          >Save</button>
        </div>
      </div>
      <div class="wrapper">
        <div class="form-group">
          <label for="title">Title</label>
          <input
            v-model="content.attributes.title"
            id="title"
            name="title"
            type="text"
          />
        </div>
        <div class="form-group">
          <label for="description">Description</label>
          <vue-editor
                  v-model="content.attributes.description"
                  :customModules="customModules"
                  :editorOptions="editorOptions"
                  :editorToolbar="customToolbar"
          ></vue-editor>
          <!--<textarea 
            v-model="content.attributes.description"
            v-autogrow
            id="description"
            name="description" 
            rows="3"
          />-->
        </div>
        <div class="form-group">
          <label for="location">Location</label>
          <input
            v-model="content.attributes.location"
            id="location"
            name="location"
            type="text"
          />
        </div>
        <div class="form-group md-6">
          <label for="online_location">Meeting Link</label>
          <input
           v-model="content.attributes.online_location"
            id="online_location"
            name="online_location"
            placeholder="Ex: http://zoom.us/123456789"
            type="text"
          />
        </div>
        <div class="form-group md-6">
          <label for="online_location_password">Meeting Password</label>
          <input
            v-model="content.attributes.online_location_password"
            id="online_location_password"
            name="online_location_password"
            placeholder="Password"
            type="text"
          />
        </div>
        <ColorPicker
          :color="computedColor"
          @changeColor="changeColor"
        />
        <div class="form-group checkboxes">
          <label>
            <input
              v-model="content.attributes.rsvp"
              type="checkbox"
            />
            <div class="square" />
            Request RSVP
          </label>
        </div>
        <EventFields :contentId="content.id" :optional="false" :populateOnCreate="true" :initial-date="dateClicked" />
        <ContentAttachments
          v-if="content"
          ref="cAttach"
          :readOnly="false"
          :content="content"
          :rallyId="parseInt(this.rallyId)"
          :channelId="parseInt(this.channelId)"
         />
      </div>
    </form>
  </article>
</template>

<script>
  import ColorPicker from "../../ColorPicker";
  import EventFields from "../../EventFields";
  import { mapState, mapActions, mapGetters } from "vuex";
  import ContentMixin from "@/components/ContentMixin.vue";
  export default {
    components: {
      ColorPicker,
      EventFields,
    },
    props: ["channel", "rally", "content"],
    mixins: [
      ContentMixin,
    ],
    data() {
      return {
        exampleColor: '#1C8291',
        dateClicked: null,
      };
    },
    computed: {
      ...mapState("calendars", ["calendarEntries", "calendarEntriesByContentId"]),
      computedColor() {
        if(this.content && this.content.attributes.color) {
          return this.content.attributes.color
        } else {
          return this.exampleColor
        }
      },
      selectedEvent(){
        return this.calendarEntriesByContentId[parseInt(this.$route.params.id)]
      }

    },
    created() {
      this.dateClicked = this.$route.query.dateClicked;
    },
    methods: {
      ...mapActions("contents", ["createContents", "updateContent"]),
      changeColor(hex) {
        this.exampleColor = hex;
        this.content.attributes['color'] = hex
      },
      saveAttachmentsThenCreate(){
        if (this.content.attributes.title){
            //there is a title...set to published
            console.log("title exists!" )
            this.content.attributes['status'] = '1'
          }
         this.$refs.cAttach.saveAttachments()
         this.$emit('perform', 'save')
      },
      cancelEvent(){
        if (!this.content.attributes.title){
            //no title...set to deleted
            this.content.attributes['status'] = '2'
            this.$emit('perform', 'save')
        }
        this.$emit('perform', 'navUp')
      }
    },
  };
</script>
