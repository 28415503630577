// import Vue from 'vue';
import { createApp } from "vue";
// import VeeValidate from "vee-validate";

import { store } from './store';
import { router} from './common'; 
import { HB_LOGGING } from "./common";
//import {router} from './common/member-router';
import MyApp from "./app/App";
import RHVueComponent from "./app/RHVueComponent";
import VuePageTransition from "vue-page-transition";
import {
  Filesystem,
  FilesystemDirectory,
  FilesystemEncoding,
} from "@capacitor/core";
//import InfiniteLoading from "vue-infinite-loading";
import FullCalendar from "@fullcalendar/vue3";
//import Popover from "vue-js-popover";
//import VueLogger from "vuejs-logger";
import { loggerOptions } from "./common";
import ImageUploader from "vue-image-upload-resize";
import {
  Cropper,
  StencilPreview,
  BoundingBox,
  DraggableArea, 
} from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import Datepicker from "vue3-datepicker";
import VueTextareaAutogrowDirective from "vue-textarea-autogrow-directive";
import InfiniteLoading from "v3-infinite-loading";
import VueTimepicker from "vue3-timepicker";
import 'vue3-timepicker/dist/VueTimepicker.css'
import VueGoogleMaps from "@fawmi/vue-google-maps";
//import GMapMap  from "@fawmi/vue-google-maps"
//import GMapMarker  from "@fawmi/vue-google-maps"

import Emitter from "tiny-emitter";
export const eventBus = new Emitter();
//custom components
import ActivityFeedContent from "./components/ActivityFeedContent";
import ActivityFeedUserAvatar from "./components/ActivityFeedUserAvatar";
import AttachedFile from "./components/AttachedFile";
import ContentAttachments from "./components/ContentAttachments";
import AvatarUpload from "./components/AvatarUpload";
import ContentParticipations from "./components/ContentParticipations";
import ManageAttendees from "./components/ManageAttendees";
import PayableParticipations from "./components/PayableParticipations";
import DashboardNav from "./components/DashboardNav";
import DetailsIcon from "./components/DetailsIcon";
import DirectoryMember from "./components/DirectoryMember";
import EmptyState from "./components/EmptyState";
import GiveParticipation from "./components/GiveParticipation";
import MembersMember from "./components/MembersMember";
import MembersComponent from "./components/MembersComponent";
import OpenRhWeb from "./components/OpenRhWeb";
import OpenRhWebSpecialEvents from "./components/OpenRhWebSpecialEvents";
import OpenRhWebPayments from "./components/OpenRhWebPayments.vue";
import OpenRhWebGives from "./components/OpenRhWebGives.vue";
import PayParticipation from "./components/PayParticipation";
import ShareIcon from "./components/ShareIcon";
import UnshareIcon from "./components/UnshareIcon";
import DeleteButton from "./components/DeleteButton";
import DeleteIcon from "./components/DeleteIcon";
import ContentElipsisMenu from "./components/ContentElipsisMenu";
import MemberElipsisMenu from "./components/MemberElipsisMenu";
import ContentPin from "./components/ContentPin";
import PayableElipsisMenu from "./components/PayableElipsisMenu";
import DirectMessageModal from "./components/DirectMessageModal";
import ToolBar from "./components/ToolBar";
import UserAvatar from "./components/UserAvatar";
import UserMenu from "./components/UserMenu";
import Comments from "./components/Comments";
import PromoteIcon from "./components/PromoteIcon";
import RhNotification from "./components/RhNotification";
import RallyNav from "./components/RallyNav";
import Sentiments from './components/Sentiments'
import SignupParticipation from './components/SignupParticipation'
import AdminSignupParticipation from './components/AdminSignupParticipation'
import StandardContent from './components/StandardContent'
import Rally from './components/Rally'
import StripeAccount from './components/StripeAccount'
import PayableEventParticipation from './components/PayableEventParticipation'
import SimplePayableEventParticipation from './components/SimplePayableEventParticipation.vue'
import EventParticipation from './components/EventParticipation'
import DarkOverlay from './components/DarkOverlay'
//import Viewer from 'v-viewer'
import { StripeElementCard } from '@vue-stripe/vue-stripe';
//import VueGtag from "vue-gtag";
import HoneybadgerVue from "@honeybadger-io/vue";


// Content-specific Componenets (new naming convention)
import AlbumDetails from './components/content/album/Details'
import AlbumForm from './components/content/album/Form'
import AlbumListed from './components/content/album/Listed'
import BoardDetails from './components/content/board/Details'
import BoardForm from './components/content/board/Form'
import BoardListed from './components/content/board/Listed'
import PrivateMessageForm from './components/content/private_message/Form'
import PrivateMessageListed from './components/content/private_message/Listed'
import EventActivityFeedListed from './components/content/event/ActivityFeedListed'
import EventForm from './components/content/event/Form'
import EventDetails from './components/content/event/Details'
import FileActivityFeedListed from './components/content/file/ActivityFeedListed'
import FileForm from './components/content/file/Form'
import FileListed from './components/content/file/Listed'
import FileDetails from './components/content/file/Details'
import FolderDetails from './components/content/folder/Details'
import FolderForm from './components/content/folder/Form'
import FolderListed from './components/content/folder/Listed'
import FolderMoveModal from './components/content/folder/MoveModal'
import GivePayableGroupDetails from './components/content/give_payable_group/Details'
import GivePayableGroupListed from './components/content/give_payable_group/Listed'
import LinkActivityFeedListed from './components/content/link/ActivityFeedListed'
import LinkListed from './components/content/link/Listed'
import LinkForm from './components/content/link/Form'
import PayableEventActivityFeedListed from './components/content/payable_event/ActivityFeedListed'
import PayableEventDetails from './components/content/payable_event/Details'
import PayableEventListed from './components/content/payable_event/Listed'
import PayableGroupDetails from './components/content/payable_group/Details'
import PayableGroupListed from './components/content/payable_group/Listed'
import PhotoLightbox from './components/content/photo/Lightbox'
import PhotoListed from './components/content/photo/Listed'
import SignupActivityFeedListed from './components/content/signup/ActivityFeedListed'
import SignupEventDetails from './components/content/signup/Details'
import SignupEventForm from './components/content/signup/Form'
import SignupEventListed from './components/content/signup/Listed'
import SignupListDetails from './components/content/signup_list/Details'
import SignupListForm from './components/content/signup_list/Form'
import SignupListListed from './components/content/signup_list/Listed'
import WallMessageForm from './components/content/wall_message/Form'
import WallMessageActivityFeedListed from './components/content/wall_message/ActivityFeedListed'
import WallMessageListed from './components/content/wall_message/Listed'
import WallMessageShared from './components/content/wall_message/Shared'
//import PayPal from 'vue-paypal-checkout'

// --- RALLY SETTINGS SPECIFIC COMPONENTS
import RallyOptions from "./components/rally_settings/RallyOptions.vue";
import ManageFeatures from "./components/rally_settings/ManageFeatures.vue";
import UserRallyPreferences from "./components/rally_settings/UserRallyPreferences.vue";
import ManagePayments from "./components/rally_settings/ManagePayments.vue";
import RallyProfile from "./components/rally_settings/RallyProfile.vue";

// --- USER SETTINGS SPECIFIC COMPONENTS
import ChangePassword from "./components/user_settings/ChangePassword.vue";
import DigestSettings from "./components/user_settings/DigestSettings.vue";
import EmailAddresses from "./components/user_settings/EmailAddresses.vue";
import NotificationSettings from "./components/user_settings/NotificationSettings.vue";
import UserAvatarUpload from "./components/user_settings/UserAvatarUpload.vue";
import UserProfile from "./components/user_settings/UserProfile.vue";
import  VueEditor  from "vue3-editor";

import * as Vue from 'vue'
const app = createApp(MyApp);
app.config.errorHandler = (err, instance, info) => {
  // err: error instance
  // instance: component instance triggering the error
  // info: Vue-specific error information (e.g., lifecycle hook, event handler)

  // Example: Log the error to the console
  console.error('Global error handler:', err);

  // Example: Display a user-friendly message (in development only)
  if (process.env.NODE_ENV === 'development') {
    // Implement a custom error display mechanism here
    // This could involve updating a component's state to show an error message
    // or using a dedicated error reporting component.
    // Avoid directly manipulating the DOM or using alert() in production.
  }
};

//app.use(VueLogger, loggerOptions);
//app.use(Popover);
app.use(VuePageTransition);
// app.use(VeeValidate);
//app.use(InfiniteLoading);//, {
  /* options */
//});
//app.use(Viewer);
app.use(VueTextareaAutogrowDirective);
app.component("FullCalendar", FullCalendar);
app.component("StripeElementCard", StripeElementCard);
app.component("infinite-loading", InfiniteLoading); 
app.component('vue-timepicker', VueTimepicker);
app.component('datepicker', Datepicker);
//app.component('GMapMap', GMapMap);
//app.component('GMapMarker', GMapMarker)
//app.component("vue-editor", VueEditor); 
//app.use(VueGtag, {
  //config: { id: "G-SHENELS5D0" },
//});

const config = {
  debug: true,
  apiKey: 'hbp_mlXot9IPVWwq3vPFQKHBAYzkMH9wla0NUGiX',
  environment: 'production',
  revision: '6.6.21'
}
if (HB_LOGGING()){
  Honeybadgerapp.install(app, config)
}
app.use(ImageUploader);
app.use(Cropper);

app.use(ImageUploader);
app.use(Cropper);
app.use(VueEditor);
app.use(FullCalendar);
app.use(VueTimepicker);
app.use(Datepicker)
//app.component('Honeybadger', HoneybadgerVue)
//register all global components here

app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBgSQKpOqISzg2WkCZ06UqmWO48NERQeP8'
    // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  }


//   //// If you intend to programmatically custom event listener code
//   //// (e.g. `this.$refs.gmap.on('zoom_changed', someFunc)`)
//   //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
//   //// you might need to turn this on.
//   // autobindAllEvents: false,

//   //// If you want to manually install components, e.g.
//   //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
//   //// app.component('GmapMarker', GmapMarker)
//   //// then disable the following:
 });

//custom components
app.component('ActivityFeedContent', ActivityFeedContent);
app.component('ActivityFeedUserAvatar', ActivityFeedUserAvatar);
app.component('AttachedFile', AttachedFile);
app.component('ContentAttachments', ContentAttachments);
app.component('AvatarUpload', AvatarUpload);
app.component('ContentParticipations', ContentParticipations);
app.component('ManageAttendees', ManageAttendees);
app.component('PayableParticipations', PayableParticipations);
app.component('DashboardNav', DashboardNav);
app.component('DetailsIcon', DetailsIcon);
app.component('DirectoryMember', DirectoryMember);
app.component('EmptyState', EmptyState);
app.component('GiveParticipation', GiveParticipation);
app.component('MembersMember', MembersMember);
app.component('MembersComponent', MembersComponent)
app.component('OpenRhWeb', OpenRhWeb)
app.component('OpenRhWebSpecialEvents', OpenRhWebSpecialEvents)
app.component('OpenRhWebPayments', OpenRhWebPayments)
app.component('OpenRhWebGives', OpenRhWebGives)
app.component('ShareIcon', ShareIcon);
app.component('UnshareIcon', UnshareIcon);
app.component('DeleteButton', DeleteButton);
app.component('DeleteIcon', DeleteIcon);
app.component('ContentElipsisMenu', ContentElipsisMenu);
app.component('MemberElipsisMenu', MemberElipsisMenu);
app.component('ContentPin', ContentPin);
app.component('PayableElipsisMenu', PayableElipsisMenu);
app.component('DirectMessageModal', DirectMessageModal);
app.component('RhNotification', RhNotification);
app.component('ToolBar', ToolBar);
app.component('UserAvatar', UserAvatar);
app.component('UserMenu', UserMenu);
app.component('Comments', Comments);
app.component('PayParticipation', PayParticipation);
app.component('PromoteIcon', PromoteIcon);
app.component('RallyNav', RallyNav);
app.component('Sentiments', Sentiments);
app.component('SignupParticipation', SignupParticipation);
app.component('AdminSignupParticipation', AdminSignupParticipation);
app.component('StandardContent', StandardContent);
app.component('PayableEventParticipation', PayableEventParticipation);
app.component('SimplePayableEventParticipation', SimplePayableEventParticipation);
app.component('EventParticipation', EventParticipation);
app.component('DarkOverlay', DarkOverlay);
app.component('Rally', Rally);
app.component('StripeAccount', StripeAccount)

// Content-specific Componenets (new naming convention)
app.component('AlbumDetails', AlbumDetails);
app.component('AlbumForm', AlbumForm);
app.component('AlbumListed', AlbumListed);
app.component('BoardDetails', BoardDetails);
app.component('BoardForm', BoardForm);
app.component('BoardListed', BoardListed);
app.component('PrivateMessageForm', PrivateMessageForm);
app.component('PrivateMessageListed', PrivateMessageListed);
app.component('EventActivityFeedListed', EventActivityFeedListed);
app.component('EventForm', EventForm);
app.component('EventDetails', EventDetails);
app.component('FileActivityFeedListed', FileActivityFeedListed);
app.component('FileForm', FileForm);
app.component('FileListed', FileListed);
app.component('FileDetails', FileDetails);
app.component('FolderDetails', FolderDetails);
app.component('FolderForm', FolderForm);
app.component('FolderListed', FolderListed);
app.component('FolderMoveModal', FolderMoveModal)
app.component('GivePayableGroupDetails', GivePayableGroupDetails);
app.component('GivePayableGroupListed', GivePayableGroupListed);
app.component('LinkActivityFeedListed', LinkActivityFeedListed);
app.component('LinkListed', LinkListed);
app.component('LinkForm', LinkForm);
app.component('PayableEventActivityFeedListed', PayableEventActivityFeedListed);
app.component('PayableEventDetails', PayableEventDetails);
app.component('PayableEventListed', PayableEventListed);
app.component('PayableGroupDetails', PayableGroupDetails);
app.component('PayableGroupListed', PayableGroupListed);
app.component('PhotoLightbox', PhotoLightbox);
app.component('PhotoListed', PhotoListed);
app.component('SignupActivityFeedListed', SignupActivityFeedListed);
app.component('SignupEventDetails', SignupEventDetails);
app.component('SignupEventForm', SignupEventForm);
app.component('SignupEventListed', SignupEventListed);
app.component('SignupListDetails', SignupListDetails);
app.component('SignupListForm', SignupListForm);
app.component('SignupListListed', SignupListListed);
app.component('WallMessageDetails', WallMessageListed);
app.component('WallMessageForm', WallMessageForm);
app.component('WallMessageActivityFeedListed', WallMessageActivityFeedListed);
app.component('WallMessageListed', WallMessageListed);
app.component('WallMessageShared', WallMessageShared);

// --- RALLY SETTINGS COMPONENTS
app.component("RallyOptions", RallyOptions);
app.component("ManageFeatures", ManageFeatures);
app.component("UserRallyPreferences", UserRallyPreferences);
app.component("ManagePayments", ManagePayments);
app.component("RallyProfile", RallyProfile);

// --- USER SETTINGS COMPONENTS
app.component("ChangePassword", ChangePassword);
app.component("DigestSettings", DigestSettings);
app.component("EmailAddresses", EmailAddresses);
app.component("NotificationSettings", NotificationSettings);
app.component("UserAvatarUpload", UserAvatarUpload);
app.component("UserProfile", UserProfile);

app.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        try {
          vnode.context[binding.expression](event);
        } catch (e) {
          console.log(e);
        }
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});
//updatethis

export const globalVueRef = eventBus;

//export const Storage = thestorage;

//   new Vue({
//     el: '#app',
//     data: {
//       paypal: {
//         sandbox: 'AebrtfvrdVtPgkOWyyUxB4Y_57X5p1Dqa8tfFXbsnIjjHIo8pKSC0SZD4jwODbq6nnoRokSFfUv6Gsbn',
//         production: 'donthavethisyet'
//       }
//     },
//     router,
//     store,
//     render: h => h(MyApp)
// });
document.addEventListener("DOMContentLoaded", async () => {
  app.use(router);
  app.use(store);
  app.mount("#app");
});
/*
new Vue({
    el: '#app',
    data: {
    },
    router,
    store,
    render: h => h(RHVueComponent)
});*/
