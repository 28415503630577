import axios from "axios";
import {
  mergeById,
  sortByDate, RCS_URL, buildServiceParamMap, RCS_SHARE_URL
  } from "../../common";


import { userInfo } from "../../common/user-info";
import { authHeader } from "../../common/auth-header";
import jwtInterceptor  from '../../common/jwt-interceptor';

const state = {
  sharesByContentId: {},
  sharesByShareId: {},
  sharesStatusByContentId: [],
};

const getters = {
  sharesByShareId: state=> {
    return state.sharesByShareId;
  },
  sharesByContentId: state => {
    return state.sharesByContentId;
  },
  sharesStatusByContentId: state=> {
    return state.sharesStatusByContentId;
  }
};

const actions = {
  async setSharesByContentId({ commit, state }, inparams) {
    commit("setSharesByContentId", inparams);
  },

  async setSharesByShareId({ commit, state }, inparams) {
    commit("setSharesByShareId", inparams);
  },

  async setSharesStatusByContentId({ commit, state }, inparams) {
    commit("setSharesStatusByContentId", inparams);
  },

  async clearShareState({ commit, state }, inparams) {
    commit("clearState", inparams);
  },

  async clearContentShareState({ commit, state }, inparams) {
    commit("clearSharesByContentIdState", inparams);
  },

  //http://localhost:3020/api/v1/shares.json?content_uid=19023978&page%5Bsize%5D=200 response_code=200 return_code=ok total_time=0.306446
  async fetchSharesByContentId({ commit, state }, inparams) {
    const { contentId, contextRallyId, contextChannelId } = inparams;
    const user = userInfo();
    var jsonparams = {
      //content_id: contentId,
      content_uid: contentId,
     // context_rally_id: contextRallyId,
     // context_channel_id: contextChannelId,
      "page[number]": "1",
      "page[size]": "200",
    };
    var params = buildServiceParamMap(jsonparams);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      url: RCS_SHARE_URL()   ,
      params
    };
    const response = await jwtInterceptor(requestOptions);

    await this.dispatch("shares/setSharesByContentId", {id: contentId, shares: response.data.data})
  },


  //Started POST "/api/v1/shares.json" for 3.129.232.47 at 2022-01-14 12:57:33 +0000
  //Processing by Api::V1::SharesController#create as JSON
  // Parameters: {"data"=>{"type"=>"shares", "attributes"=>{"content_uid"=>"19045084", "context_channel_id"=>["35143", "178867", "306610"], "context_id"=>["35143", "178867", "306610"], "context_rally_id"=>["36361", "38455", "41405"], "context_type"=>"Channel", "creator_id"=>303137, "status"=>1, "share_type"=>"shared", "owner"=>false, "unshare_on_create"=>true}}}
  async shareContent({ commit, state}, inparams) {
    const { contentUid, channelIds, rallyIds, status, owner, shareMessage, sharedFromId, shareType, contextId, contextType} = inparams;
    const user = userInfo();

    let contextIdParam = contextId ? contextId : channelIds
    let contextTypeParam = contextType ? contextType : 'Channel'
    let shareTypeParam = shareType ? shareType : 'shared'

    const createdAttributes = {
      rallyIds,
      content_uid: contentUid,
      context_channel_id: channelIds,
      context_id: contextIdParam,
      context_rally_id: rallyIds,
      context_type: contextTypeParam,
      status: status,
      share_type: shareTypeParam,
      owner: owner,
      unshare_on_create: false,
      creator_id: user.user_id,
      share_message: shareMessage,
      shared_from_id: sharedFromId
    };

    const requestOptions = {
      method: "POST",
      url: RCS_SHARE_URL(),
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "shares",
          attributes: createdAttributes
        }
      }
    };

    //commit("setAllMessages", [{id:123, message: {attributes: createdAttributes}}]);
    try {
      const response = await jwtInterceptor(requestOptions);
      this.dispatch("shares/setSharesStatusByContentId", {id: contentUid, status: "success"})

    } catch (error){
        console.log(error)
    }

    //this.dispatch("messages/fetchAllMessages", inparams);
  },
  async deleteShare({ commit, state }, inparams) {
    const { id, rallyId } = inparams;
    const user = userInfo();
    var jsonparams = {
      id,
      context_rally_id: rallyId
    }
    var params = buildServiceParamMap(jsonparams);
    const requestOptions = {
      method: "DELETE",
      url: RCS_SHARE_URL() + id,
      headers: {
        "Content-Type": "application/json"
      },
    };
    const response = await jwtInterceptor(requestOptions);
    commit("clearSharesByShareId", {id});
  },


/*
  Parameters: {"data"=>{"type"=>"shares", "attributes"=>{"content_uid"=>"19033299", 
  "context_channel_id"=>["35143", "177408", "178867", "290363", "306610"], 
  "context_id"=>["35143", "177408", "178867", "290363", "306610"], 
  "context_rally_id"=>["38312", "38677", "36361", "38455", "41405"], 
  "context_type"=>"Channel", "creator_id"=>303137, "status"=>1, "share_type"=>"shared", "owner"=>false, 
  "unshare_on_create"=>true}}}
  */
 /*
  async shareContent({ commit, state }, inparams) {
    const { content, rallyId } = inparams;
    const user = userInfo();
    const createdAttributes = {
        type: "shares",
        content_uid:  content.id,
        content_type: content.attributes['content_type'],
        context_rally_id: rallyId,
        participant_count: content.attributes['participant-count'],
    };
    const requestOptions = {
      method: "POST",
      url: RCS_URL() + "/" + content.id + ".json",
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader()
      },
      data: {
        data: {
          type: "shares",
          attributes: createdAttributes
        }
      }
    };
    const response = await axios(requestOptions);
    commit("setContentById", {id: content.id, content})
    //this.dispatch("messages/fetchAllMessages", inparams);
  }*/

 

};

const mutations = {
  clearState: state => {
    state.sharesByContentId = {};
    state.sharesByShareId = {};
    state.sharesStatusByContentId = [];
  },

  clearSharesByContentIdState: (state, params) => {
    state.sharesByContentId = {};
  },
  setSharesStatusByContentId:(state, params) => {
    const { id, status } = params; 
    var byIdObj = {};
    byIdObj[id] = status
    state.sharesStatusByContentId = { ...state.sharesStatusByContentId, ...byIdObj }
  },
  clearSharesByShareId: (state, params) => {
    const { id } = params;
    var byIdObj = {}
    byIdObj[id] = null;
    state.sharesByShareId = {...state.sharesByShareId, ...byIdObj};
  },
  setSharesByShareId: (state,params) =>{
    const {share} = params
    var byIdObj = {}
    byIdObj[share.id] = share 
    state.sharesByShareId = {...state.sharesByShareId, ...byIdObj}
  },
  setSharesByContentId: (state, params) => {
    const { id, shares } = params;
    var curarr = state.sharesByContentId[id];
    if (!curarr) {
      curarr = [];
    }
    var newarr = mergeById(curarr, shares, "id");
    
    var byIdObj = {};
    byIdObj[id] = sortByDate(newarr, "attributes",  "created-at");
    state.sharesByContentId = { ...state.sharesByContentId, ...byIdObj };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
