<template>
  <div class="comments">
      <div v-if="!show_editor" >
        <form
          class="content comment new waiting"
          method="post"
        >
          <UserAvatar
           :user="user"
           p-line-height="1.5rem" p-font-size=".8rem"
          ></UserAvatar>
          <textarea
            @click="newCommentObj"
            class="body"
            id="body"
            name="body"
            rows="1"
            placeholder="Write a comment..."
          ></textarea>
        </form>
      </div>
      <form
        v-if="show_editor"
        @submit.prevent="doCreateComment()"
        method="post"
      >
        <article class="content listed comment">
          <UserAvatar :avatarFile="avatarFile" :user="user" p-line-height="1.5rem" p-font-size=".8rem"/>
          <div class="body">
            <div class="form-group">
              <vue-editor
                  v-model="newCommentBody"
                  :customModules="customModules"
                  :editorOptions="editorOptions"
                  :editorToolbar="customToolbar"
              ></vue-editor>
            </div>
          </div>
          <div class="actions">
            <button class="secondary"  @click.prevent="cancelSaveComment()" >Cancel</button>
            <button class="primary"
                    v-bind:disabled="isSaving"
                    v-bind:class="buttonClass">
            {{ isSaving ? "Posting..." : "Post" }}</button>
          </div>
        </article>
      </form>
    <div v-for="c in sortedComments" :key="c.id">
      <article v-if="c.attributes.body && !c.deleted" class="content listed comment">
        <UserAvatar :pUserId="c.attributes['creator-id']" p-line-height="1.5rem" p-font-size=".8rem"/>
        <div v-if="editingId == c.id">
          <div class="body">
            <div class="form-group">
              <vue-editor
                  v-model="newCommentBody"
                  :customModules="customModules"
                  :editorOptions="editorOptions"
                  :editorToolbar="customToolbar"
              ></vue-editor>
            </div>
          </div>
          <div class="actions"> 
            <DeleteButton @click="destroy(c)" />
            <button class="secondary" @click.prevent="cancelSaveComment()" >Cancel</button>
            <button class="primary" @click.prevent="saveComment(c)" >Save</button>
          </div>
        </div>
        <div v-else>
          <div class="body">
            <span v-if="c.attributes['creator-first-name']" class="author-name">
              {{c.attributes['creator-first-name']}} {{c.attributes['creator-last-name']}} -
            </span>
            <span class="created-at" >
               {{formatDate( c.attributes['created-at'])}}
            </span>
            <div v-html="c.attributes.body">
            </div>
           
            <i  v-if="userIsOwner(c)" class="edit-comment fal fa-pencil" @click="editComment(c)"></i>
           
          </div>
        </div>
        <div class="actions" v-click-outside="hideActions">
        <Sentiments
            :message-id="c.id"
            :likeOnly=true
            :shareId="contextShareId(c)"
        />
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions, mapGetters } from "vuex";
import { router, sortByDateOlderFirst, compareBy } from "../common";
import QuillMention from "quill-mention";
import {query} from "vue-gtag";

export default {
  name: "Comments",
  props: ["shareId", "avatarFile", "channelId", "rallyId"],
  data() {
    return {
      isSaving: false,
      buttonClass: 'primary',
      loaded: false,
      comments: [],
      show_editor: false,
      newCommentBody: "" ,
      editingId: 0,
      customModules: [{ alias: "quillMention", module: QuillMention }],
      customToolbar: [
        "bold",
        "italic",
        "underline",
        { list: "bullet" },
        { color: [] },
        { background: [] },
        //"image",
        "link",
        { align: [] }
      ],
      editorOptions: {
        modules: {
          quillMention: {
            allowedChars: /^[A-Za-z0-9\s]*$/,
            mentionDenotationChars: ["@"],
            source: async function(searchTerm, renderList) {
              // TODO: Query RMS
              // const raw =  this.memberSearchResults;

              const matchedPeople = await this.searchMembersByRally(searchTerm, this.rallyId);
              const raw = this.memberSearchResults;
              const users = this.profileByMembershipId;
              const members = this.membersByRallyId;

              // Parse the results to the format QuillMention expects:
              // { id: String, avatar: String, value: String }

              let results = [];
              //for (const result : raw) {
              for (const result of raw) {
                //alert(result);
                //alert(result.data);
                //alert(result.data.attributes);
                //alert(result.data.attributes.user.first_name);

                results.push({
                  //   avatar: raw[result].avatar,
                  id: result.attributes['user-id'],
                  value: users[result.id].attributes['first-name'] + " " + users[result.id].attributes['last-name']
                });
              }

              // As you type, filter results on client side as well for faster response.
              if (searchTerm.length === 0) {
                renderList(results, searchTerm);
              } else {
                const matches = [];
                for (let i = 0; i < results.length; i++) {
                  if (
                      ~results[i].value
                          .toLowerCase()
                          .indexOf(searchTerm.toLowerCase())
                  ) {
                    matches.push(results[i]);
                  }
                }
                renderList(matches, searchTerm);
              }
            }.bind(this),
            renderItem(item) {
              //return `<img src="${item.avatar}">${item.value}`;
              return `${item.value}`;
            }
          }
        }
      }
    };
  },
  computed: {
    ...mapState("user", ["status", "user"]),
    ...mapState("messages", ["commentsByShareId"]),
    ...mapState("memberships", [
      "memberSearchResults",
      "profileByMembershipId",
      "membersByMemberId",
      "membersByRallyId"
    ]),
    ...mapState("shares", ["sharesByContentId"]),

    sortedComments(){
      return this.sortComments(this.commentsByShareId[this.shareId])
    },

  },
  created() {
    this.fetchAMessageComments({ shareId: this.shareId, channelId: this.channelId });
  },
  methods: {
    ...mapActions("messages", ["createComment", "fetchAMessageComments", "addCommentToStore"]),
    ...mapActions("contents", ["updateContent", "deleteContent"]),
    ...mapActions("memberships", ["searchMembers"]),
    hideActions() {
      this.showElipsis = false;
    },
    contextShareId(comment) {
        if (comment.relationships && comment.relationships['context-share']){
          return comment.relationships['context-share'].data.id
        } else {
          return null 
        }
    },
    perform(method, params) {
      if (this[method]) {
        params ? this[method](params) : this[method]();
      } else {
        this.$emit("perform", method, params);
      }
    },
    searchMembersByRally(query, rallyId) {
      this.searchMembers({
        status: ["Active"],
        query: query,
        rallyId: rallyId,
        pageNumber: 1,
        queryField: 'name'
      });
    },
    userIsOwner(c){
      return (c && c.attributes && c.attributes["creator-id"] == this.user.user_id)
    },

    sortComments(comments){
     // return comments
     if (comments && comments.length > 1){
     return comments.sort(compareBy({}, "created-at"))
     } else {
       return comments
     }
    // return  this.sortByDate(comments, "attributes", "created-at");
    },
    destroy: function(c) {
      this.deleteContent({ id: c.id, rallyId: this.rallyId });
      c.deleted = true
      this.editingId = null
    },
    editComment: function(c){
      this.editingId = c.id
      this.newCommentBody = c.attributes.body
      this.$nextTick(() => {
        this.$refs.body[0].focus();
      });
    },
    cancelSaveComment: function(c){
      console.log("cancelSaveComment...")
      this.editingId = null
      this.show_editor = false;
      this.newCommentBody = "";
    },
    saveComment: function(c){
      console.log("SaveComment...")
      c.attributes.body = this.newCommentBody
      this.updateContent({id: c.id, content:c, rallyId: this.rallyId}) 
      //this.fetchAMessageComments({ shareId: this.shareId, channelId: this.channelId });
      this.editingId = null
    },
    doCreateComment: async function() {
      this.isButtonDisabled = true;
      this.buttonClass = 'dimmed';
      this.show_editor = false;
      this.addCommentToStore({comment: {id : Math.floor(Math.random() * 10000), shareId: this.shareId,  attributes:{body: this.newCommentBody,'created-at': new Date().toISOString(), 'creator-avatar':this.avatarFile, 'creator-id':this.user.user_id} }})
      await this.createComment({  rallyId: this.rallyId, shareId : this.shareId, body: this.newCommentBody, channelId: this.channelId});
      this.newCommentBody = "";
      this.isButtonDisabled = false;
      this.buttonClass = 'primary';
      this.$store.dispatch('user/flashSuccess', 'Comment Has Been Saved!');
    },
    newCommentObj: function() {
      this.show_editor = true;
      //this.$nextTick(() => {
        //this.$refs.body.focus();
     // });
    },

    formatDate(ds){
      return moment(ds).format("MM/DD/YYYY hh:mm a")
    },

    log(message) {
      this.$log.debug(message)
    }
  }
};
</script>
