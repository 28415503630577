<template>
  <div v-if="params && params.heading" :class="[{ open: modalOpen }, 'modal', '-modal']">
    <div class="shadow" @click="closeModal"></div>
    <div class="body">
      <i class="fal fa-times exit" @click="closeModal"></i>
        <div >
          <div class="heading">{{params.heading}}</div>
          <div class="description">
            {{params.description}}
          </div>
          <div class="actions">
            <div class="left">
              <button class="secondary" @click="closeModal">Cancel</button>
            </div>
            <div class="right">
              <button class="primary" @click="openRhWebTab('{{params.returnTo}}')">GET STARTED!</button>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
  import { accessToken } from "../common/auth-header"
  import { RH_URL, router} from "../common";
  import { eventBus } from "../main.js";
  import { Capacitor } from "@capacitor/core";
  import {openRhWebBrowserCreate} from "../common/utils";
  import { Browser } from "@capacitor/browser";
  export default {
    props: [ 'params', 'uniqueId' ],
    data() {
      return {
        openModal: 'no'
      }
    },
    created(){
      console.log("registering.... openRHWebEvent " + this.uniqueId)
      eventBus.on('openRHWebEvent', (returnTo) => {
          console.log("received ...openRHWebEvent" + this.uniqueId)
          this.openRhWebTab(returnTo);
          //this.openModal = 'yes'
      });

    },
    computed: {
      modalOpen() {
        return this.openModal == 'yes'
      },
    },
    methods: {
      
      closeModal() {
        //this.$emit('perform', 'openRHWeb', null);
        this.openModal = 'no';
      },

      openRhWebTab(returnTo) {
        try{
          console.log(this.params)
          openRhWebBrowserCreate(this.$route.params.rallyId, returnTo)
        } catch (e){
          console.log(e)
        }
        this.openModal= 'no'
      }

    }
  }
</script>
