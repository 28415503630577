<template>
  <article>
    <div class="activity-feed">
      <div>
        <div v-on:click="goToRally" class="af-rally-hero">
          <img style="max-height: 40px; float: left;" :src="content.attributes['rally-hero-url']"  >
        </div>
        <div class="af-rally-title">
          <a v-on:click="goToRally" >{{ content.attributes['rally-title'] }}</a>
        </div>
      </div>
      <hr class="af-hr-rally-title"/>
      <div class="af-event-section" >
        <div class="af-event-section-left">
          <div>
            <div>
              <div style="width:25%" v-if="content.attributes['start-date']">
                <div class="af-event-date-circle">
                  <div class="af-event-date-month">
                    {{ doFormatMonth }}
                  </div>
                  <div class="af-event-date-day">
                    {{ doFormatDay }}
                  </div>
                </div>
              </div>
              <div v-if="content.attributes['start-date']" class="af-event-start">
                <a v-on:click="$emit('perform', 'openContent', content)" class="af-title">{{
                    content.attributes.title
                  }}</a>
              </div>
              <div v-if="!content.attributes['start-date']" class="af-event-no-start">
                <a v-on:click="$emit('perform', 'openContent', content)" class="af-title">{{
                    content.attributes.title
                  }}</a>
              </div>
              <div v-if="content.attributes['start-date']" class="af-event-date">
                {{ doFormatDateEvent }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="af-body" >
            <div v-html="content.attributes.description"/>
            <ContentAttachments v-on:perform="perform" :readOnly="true" :content="content" :rallyId="parseInt(this.rallyId)" :channelId="parseInt(this.channelId)" />
          </div>
      <div>
        <div>
            <ActivityFeedUserAvatar
                :pUserId="content.attributes['originator-id']"
                :pSize="'25px'"
                :pRallyId="content.attributes['rally-id']"
                :pAvatarFile="content.attributes['originator-avatar-url']"
                :pUserFirstName="content.attributes['originator-first-name']"
                :pUserLastName="content.attributes['originator-last-name']"
            ></ActivityFeedUserAvatar>
          </div>
        <div class="af-author-name">
          Signup created by
          {{ content.attributes['originator-first-name'] }} {{ content.attributes['originator-last-name'] }}
        </div>
        <div class="af-posted-rally">
          in <u><a v-on:click="goToRally">{{ content.attributes['rally-title'] }}</a></u>
        </div>
        <div class="af-posted-when" >
          {{ when }}
        </div>
      </div>
      <div class="actions" style="margin-top:10px">
        <Sentiments
            :message-id="content.attributes['activity-id']"
            :likeOnly=false
            :shareId="content.attributes['context-share-id']"
        />
      </div>
    </div>
  </article>
</template>

<script>

import moment from "moment-timezone";
import { mapState, mapActions, mapGetters } from "vuex";
import {formatDateEvent, kebabCase, railsTzToMomentTz, router} from "../../../common";
import ContentMixin from "../../ContentMixin";
import {userInfo} from "@/common/user-info";

export default {
  name: "SignupActivityFeedListed",
  props: ["id", "channelId", "content"],
  data() {
    return {
      rallyId: null,
      page: 1,
      show_create: false,
      showElipsis: false,
      polling_int: null,
      newMessage: {
        title: null,
        body: null
      }
    };
  },
  computed: {
    ...mapState("user", ["status", "user", "usersById"]),
    ...mapState("messages", ["allMessagesByRallyId"]),
    ...mapState("contents", ["allContentByContextTypeAndId", "contentById"]),
    ...mapState("shares", ["sharesByContentId"]),

    contentTitle(){
      return this.content.attributes.title
    },
    contentBody(){
      return this.content.attributes.body
    },
    creatorId() {
      return this.content['attributes']['user-id']
    },
    isEditable() {
      return false
    },
    rallyIdForUserAvatar(){
      if (this.ownerShare && this.ownerShare.attributes && this.ownerShare.attributes['context-rally-id']){
        return parseInt(this.ownerShare?.attributes['context-rally-id'])
      } else {
        return parseInt(this.rallyId)
      }
    },
    doFormatDay(){
      let tz = railsTzToMomentTz(userInfo().timezone)
      let startFormat = "DD"
      return  moment(this.content.attributes['start-date']).tz(tz).format(startFormat)
    },
    doFormatMonth(){
      let tz = railsTzToMomentTz(userInfo().timezone)
      let startFormat = "MMM"
      return  moment(this.content.attributes['start-date']).tz(tz).format(startFormat)
    },
    doFormatDateEvent(){
      let tz = railsTzToMomentTz(userInfo().timezone)
      let allDay = (this.content.attributes['all-day'] == 1 || this.content.attributes['all_day'] == 1)
      let startFormat = "MMM D, h:mm a"
      let dateFormatDay = 'MMM D'
      let hourFormat = 'h:mm a'
      let startDate = moment(this.content.attributes['start-date']);
      let endDate = moment(this.content.attributes['end-date']);
      var endFormat = "h:mm a";

      if (allDay && startDate != endDate) {
        startFormat = "MMM D"
        endFormat  = "MMM D"
        endDate = endDate.subtract(1, 'days')
      } else if (allDay && startDate == endDate) {
        startFormat = "MMM D"
        endFormat = null
      } else if (startDate != endDate) {
        endFormat = "MMM D, h:mm a";
      }


      if (allDay && moment(startDate).tz(tz).format(startFormat) == moment(endDate).tz(tz).format(startFormat)){
        return moment(startDate).tz(tz).format(startFormat)
      } else {
        if (moment(startDate).tz(tz).format(dateFormatDay) == moment(endDate).tz(tz).format(dateFormatDay)) {
          return moment(startDate).tz(tz).format(startFormat) + (!endFormat ? "" : " - " +
              moment(endDate).tz(tz).format(hourFormat))
        } else {
          return moment(startDate).tz(tz).format(startFormat) + (!endFormat ? "" : " - " +
              moment(endDate).tz(tz).format(endFormat))
        }
      }
    },
    when() {
      return this.content ? this.formatDate(this.content.attributes["created-at"]) : "";
    }
  },
  created() {
    this.rallyId = this.$route.params.rallyId;
    this.$route.params["pageNumber"] = 1;
  },

  methods: {
    ...mapActions("messages", [
      "fetchAMessage",
      "createMessage",
    ]),
    goToRally() {
      router.push(`/${this.content.attributes['rally-id']}/messages`);
    },
    hideActions() {
      this.showElipsis = false;
    },

    perform(method, params) {
      if ( this[method] ) {
        params ? this[method](params) : this[method]();
      } else {
        this.$emit('perform', method, params);
      }
    },
    formatDate(ds){
      return moment(ds).fromNow()
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
