<template>
  <li class="option notification">
    <img
      :src="notification.attributes['user-avatar-url']"
      class="avatar"
      style="height: 35px; width: 35px;"
    />
    <div @click="followNotification(notification)" :style="notificationStyle" >
    {{ notification.attributes['notification-text'] }}
    </div>
    <div class="when">
      {{ when }}
    </div>
  </li>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { eventBus } from "../main.js";
import {router} from "@/common";
import moment from "moment-timezone";

export default {
  props: {
    notification: Object
  },
  data() {
    return {
    };
  },
  mounted() {
    this.$attrs.observer && this.$el ? this.$attrs.observer.observe(this.$el) : null
  },
  computed: {
    notificationStyle() {
      let style = ''
      if(this.notification.attributes['status'] == 'new') {
        style = 'font-weight: bold;'
      }
      return style
    },
    when() {
      return this.notification ? moment(this.notification.attributes["created-at"]).fromNow() : "";
    }
  },
  created() {
  },
  methods: {
    followNotification(notification) {
      console.log(notification);

      eventBus.emit('selectSubchannel', notification.attributes['originator-id'])
      this.$router.push({
        path: `/messaging/${notification.attributes['originator-id']}?cid=${notification.attributes['activity-id']}`,
        query: {
          t: new Date().getTime(),
        }
      });
    },
  },
}
</script>
