<template>
  <component
    v-on:perform="perform"
    :content="content"
    :class="elementClasses"
    :is="componentType"
    :key="content.id"
    :id="content.id"
    :contentType="this.content.attributes['content-type']"
    :channelId="this.content.attributes['channel-id']"
    :rallyId="this.content.attributes['rally-id']"
    :pUserId="pUserId"
    :contextShare="contextShare"
    ref="contentComponent"
  />
</template>

<script>
  import { mapState, mapActions, mapGetters } from "vuex";
  import ContentMixin from "./ContentMixin";
  import { eventBus } from "../main.js";
  import moment from "moment-timezone";
  import { WallMessageListed } from "../main.js"
  import {
    kebabCase, router, findDefaultChannelOfTypeForRally, slugToTypeObjectMap 
  } from "../common";
  export default {
    props: {
      parentContextIds: Array,
      parentContextId: String,
      content: Object,
      pUserId: String,
      detailed: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        editing: false
        //isNewContent: false
      };
    },
    mounted() {
       this.$attrs.observer && this.$el ? this.$attrs.observer.observe(this.$el) : null
    },
    computed: {
     // ...mapState("rallies", ["rallyChannelsByRallyId", "allRalliesByRallyId"]), 
    ...mapState("contents", ["contentById"]),
    ...mapState("channels", ["channelsById"]),
      ...mapState("shares", ["sharesByContentId"]),
      componentType() {
        if ( this.contentType == 'WallMessage') {
          return "WallMessageActivityFeedListed";
        } else if (this.contentType == 'CalendarEvent') {
            return "EventActivityFeedListed"
        } else if (this.contentType == 'Link') {
            return "LinkActivityFeedListed"
        } else if (this.contentType == 'SignupEvent') {
            return "SignupActivityFeedListed"
        } else if (this.contentType == 'File') {
            return "FileActivityFeedListed"
        } else if (this.contentType == 'PayableEvent') {
            return "PayableEventActivityFeedListed"
        } else {
            return 'WallMessageActivityFeedListed'
        }
      },
      componentView() {
        if ( this.editing || this.unsaved || this.content.editMode || this.content?.attributes['lifecycle'] ==  "new") {
          return 'Form';
        } else if ( this.contentType == 'WallMessage' && this.shared ) {
          return 'Shared';
        } else if (this.detailed ) {
          return 'Details';
        } else {
          return 'Listed';
        }
      },
      contentType() {
        //return this.content.attributes['type'];
        let contentType =  this.content.attributes['activity-type'];
        return contentType;
      },
      elementClasses() {
        const read = 'listed';
        const type = 'wall-message'
        //const read = 'details'
        //const type = 'event'
        return `content ${read} ${type}`;
      },
      lifeCycle(){
        return this.content.attributes['lifecycle'];
      },
      contextShare() {
        var rallyId = this.$attrs.rallyId
        if (this.shares && this.shares.filter){
          return this.shares.filter(function(s) {
            return s.attributes['context-rally-id'] == rallyId;
          })[0];
        } else {
          return {}
        }
      },
      shares(){
        return this.sharesByContentId[parseInt(this.content.id)]
      },
      shared() {
        return  this.contextShare.attributes  &&   !this.contextShare.attributes.owner //( Math.random() > 0.5 ) && this.contentType == 'WallMessage';
      },
      unsaved() {
        return !this.content || (this.content.id == null &&  this.content.tempKey == null)
      },
    },
    created() { 
      eventBus.on('editContent' , (p) => {
        if (this.content && (p == this.content.id))
        this.edit()
      })
    },
    methods: {
      ...mapActions("messages", ["createMessage", "saveMessage"]),
      ...mapActions("contents", ["createContents", "updateContent"]),
      channelId(){
        return this.$attrs.channelId
      },
      cancel() {
        if ( this.unsaved ) {
          this.$emit('perform', 'cancelNewInline');
        } else {
          this.editing = false;
          //this.unsaved = false;
          this.content.editMode = false;
          if (this.content.attributes){
            this.content.attributes['lifecycle'] = ""
          }
        }
      },
      edit() {
        this.editing = true;
      },
      openContainer(container) {
        this.$emit('perform', 'openContainer', container);
      },
      openContent(content) {
        this.$emit('perform', 'openContent', content);
      },
      perform(method, params) {
        if ( this[method] ) {
          params ? this[method](params) : this[method]();
        } else {
          this.$emit('perform', method, params);
        }
      }
    },
  }
</script>
