<template>
    <div class="actions" v-click-outside="hideActions">
      <i
        @click="showElipsis = !showElipsis"
        class="action ellipsis-button more fa-ellipsis-h fas"
      />
      <div class="action ellipsis-button more"> 
        <ul v-if="showElipsis" class="additional">
          <li v-if="canEditComputed" @click="emitEditEvent" class="option">
           <!-- <i class="fal fa-pencil"></i>-->Edit 
          </li>
          <li v-if="canEditComputed" @click="emitManageEvent" class="option">
            <!--<i class="fal fa-pencil"></i>-->Manage 
          </li>
          <!--<DetailsIcon :content="content"  :rallyId="this.rallyId"  :channelId="this.channelId"/>-->
        </ul>
      </div>
       <OpenRhWebSpecialEvents :content="content" :uniqueId="this.openRhWebUniqueId"/>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { eventBus } from "../main.js";
import {openRhWebBrowser} from "@/common/utils";
import UserPermissionMixin from './UserPermissionMixin.vue';
  export default {
    mixins: [UserPermissionMixin],
    props: ['content', 'rallyId', 'channelId', 'menu', 'type', 'contentType', 'hideDelete', 'hideReport', 'hideShare'],
    data: function() {
      return {
        showElipsis: false
      };
    },
    created () {

    },
    computed: {
    ...mapState("user", ["status", "user", "usersById"]),
    ...mapState("messages", ["allMessagesByRallyId"]),
    ...mapState("contents", ["allContentByContextTypeAndId", "contentById"]),
    ...mapState("shares", ["sharesByContentId"]),
    /*userIsOwner(){
      return (this.content && this.content.attributes && this.content.attributes["creator-id"] == this.user.user_id) 
    },*/
    hideDeleteComputed(){
      return this.hideDelete
    },
    openRhWebUniqueId(){
      return this.content.id
    },
    hideReportComputed(){
      return this.hideReport
    },
    hideShareComputed(){
      return this.hideShare
    },
    creatorId() {
      return this.content.attributes['creator-id']
    },
    isEditable() {
      return true //this.content.editable == 'true'
    },
    rallyIdForUserAvatar(){  
        if (this.ownerShare && this.ownerShare.attributes && this.ownerShare.attributes['context-rally-id']){
          return parseInt(this.ownerShare?.attributes['context-rally-id'])
        } else {
          return parseInt(this.rallyId)
        }
      }
    },
    methods: {
    emitEditEvent() {
      try {
        openRhWebBrowser(this.content.id, this.$route.params.rallyId, "edit")
      } catch (e){
        console.log(e)
      }
      //eventBus.emit('openRHWebSpecialEventsEdit' , this.content.id)

    },
    emitManageEvent() {
      //eventBus.emit('openRHWebSpecialEventsManage', this.content.id)

    },
    hideActions() {
      this.showElipsis = false;
    },
    }
  }
</script>
