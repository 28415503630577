<template>
  <div>
    <main :class="[channelSlug, 'index']">
      <div>
        <InvitationDrawer
            :state="invitationDrawerState"
            :rallyId="rallyId"
            @close="toggleInvitationDrawer"
        />
      </div>
      <ToolBar :options="toolbarOptions" allow-member-filter="true" v-on:perform="perform" />
      <MembersComponent :rally="rally" :channel="channel" ref="memComp"/>
    </main>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import {RCS_REPORTS_URL, RMEMS_REPORTS_URL, router} from "../common";
import { findDefaultChannelOfType } from "../common";
import InvitationDrawer from "@/components/InvitationDrawer.vue";
import {eventBus} from "@/main";
import axios from "axios";
import {userInfo} from "@/common/user-info";
export default {
  components: {
    InvitationDrawer,
  },
  props: ["channel", "rally"],
  data: function() {
    return {
      invitationDrawerState: false
    };
  },
  computed: {
    ...mapState("memberships", ["membersByRallyId"]),
    ...mapState("user", ["usersById"]),
    ...mapState("rallies", ["allRalliesByRallyId"]),

    channelSlug() {
      // Used in place of channel.type.
      return this.$route.params.channelSlug;
    },
    members() {
      return this.membersByRallyId[this.rallyId];
    },
    rallyId() {
      return this.rally.id;
    },
    selectedRally: function() {
      return this.rally;
    },
    toolbarOptions() {
      let options = new Array();

      // Disable console errors for using TRUE and FALSE in examples.
      /* eslint-disable no-constant-condition */

      // Consult rally.privacy
      /*
      not mvp
      if (true) {
        options.push({ click: "foo", icon: "fa-plus-circle", label: "Invite" });
      }
      */
      // Present for everyone
      options.push({ onClick: "openSearch", params: { context: 'members' }, icon: "fa-search", label: "Search" });
      options.push({ onClick: "refreshDir", params: { }, icon: "fa-sync", label: "Refresh" });
      options.push({ onClick: "openSettings", params: { context: 'directory' }, icon: "fa-cog", label: "Settings" });
      if (this.selectedRally.attributes.editable) {
        options.push({ onClick: "download", params: { context:  "Members"}, icon: "fa-download", label: "Download", permission:"writable" });
        options.push({ onClick: "toggleInvitationDrawer", params: { context: 'directory' }, icon: "fa-user-plus", label: "Invite Friends" });
    }

      //not mvp
      //options.push({ click: "foo", icon: "fa-star", label: "Owner & Admin" });

      // This should only be true for rally admin.
      /*
      not mvp
      if (true) {
        options.push({ click: "foo", icon: "fa-cog", label: "Settings" });
      }
      */
      return options;
    }
  },
  created() {
    eventBus.on('download', () => {
      this.downloadList();
      //alert('here')
    })
  },
  methods: {
    ...mapActions("memberships", ["fetchMembersByRally", "clearMemberState"]),
    log(message) {
      this.$log.debug(message);
    },
    downloadList() {
      const token = localStorage.getItem('at');

      axios({
        url: RMEMS_REPORTS_URL() + "?report_name=member_list"
            + "&rally_id="
            + this.rallyId,
        method: 'GET',
        responseType: 'blob', // Set the response type to 'blob' for file download
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'text/csv',
          'Accept': 'text/csv'
        },
      })
          .then(response => {
            // Create a temporary anchor element
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.rallyId + '_members.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(error => {
            console.error('Error downloading file:', error);
          });
    },
    handleMemberFilterChange(status) {
      if (status === 'only_friends') {
        this.$refs.memComp.refreshMembers( ['Friend', 'Subscriber'], ["Active"])
      } else if (status == 'admins') {
        this.$refs.memComp.refreshMembers( ['Owner', 'Admin'], ["Active"])
      } else if (status == 'all_members') {
        this.$refs.memComp.refreshMembers( ['Owner', 'Admin', 'Friend', 'Subscriber'], ["Active", "Invited"])
      } else if (status == 'all_members_and_declinees') {
        this.$refs.memComp.refreshMembers( ['Owner', 'Admin', 'Friend', 'Subscriber'], ["Active", "Invited", "Declined", "Requested"])
      } else if (status == 'declinees') {
        this.$refs.memComp.refreshMembers( ['Owner', 'Admin', 'Friend', 'Subscriber'], ["Declined"])
      } else if (status == 'invitees') {
        this.$refs.memComp.refreshMembers( ['Owner', 'Admin', 'Friend', 'Subscriber'], ["Invited"])
      } else if (status == 'requestees') {
        this.$refs.memComp.refreshMembers( ['Owner', 'Admin', 'Friend', 'Subscriber'], ["Requested"])
      }
    },
    refreshDir(){
      this.$refs.memComp.refreshMembers();
    },
    toggleInvitationDrawer() {

      this.invitationDrawerState = !this.invitationDrawerState;
    },
    perform(method, params) {
      params ? this[method](params) : this[method]();
    },

  }
};
</script>
