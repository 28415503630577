<template>
  <div  :class="[{ open: modalOpen }, 'modal', '-modal']">
    <div class="shadow" @click="closeModal"></div>
    <div class="body">
      <i class="fal fa-times exit" @click="closeModal"></i>
      <div >
        <div class="heading">{{this.modalTitle}}</div>
        <div class="description">
          {{this.modalText}}
        </div>
        <div class="actions">
          <div class="left">
            <button class="secondary" @click="closeModal">Cancel</button>
          </div>
          <div class="right">
            <button class="primary" @click="openRhWeb">OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { accessToken } from "../common/auth-header"
import { RH_URL, router} from "../common";
import { eventBus } from "../main.js";
import {openRhWebBrowser} from "@/common/utils";
import { Browser } from "@capacitor/browser";
export default {
  name: "OpenRhWebPayments",
  props: [ 'params', 'uniqueId', 'content' ],
  data() {
    return {
      openModal: 'no',
      mode: 'edit',
      modalTitle: "",
      modalText: ""
    }
  },
  created(){
  },
  computed: {
    modalOpen() {
      return this.openModal == 'yes'
    },
  },
  methods: {
    closeModal() {
      this.openModal = 'no';
    },
    openRhWeb(id) {
      try{
        openRhWebBrowser(id ? id : this.content.id, this.$route.params.rallyId, this.mode)
      } catch (e) {
        console.log(e)
      }
      this.openModal= 'no'
    }
  }
}
</script>
