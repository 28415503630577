<template>
  <component @click="handleClick" :class="elementClass" :is="elementType">
    <image-uploader
      v-if="uploader"
      :debug="0"
      :maxWidth="1024"
      :quality="0.8"
      :autoRotate=false
      outputFormat="verbose"
      :preview="false"
      :capture="false"
      accept="image/*"
      doNotResize="['gif', 'svg']"
      @input="setImage"
    />
    <i :class="itemClass"></i>
    {{ options.label }}
  </component>
</template>

<script>
export default {
  props: {
    isFirst: Boolean,
    options: Object
  },
  computed: {
    elementClass() {
      let theClass = this.isFirst ? "default" : "option";
      if (this.uploader) {
        theClass += " upload-wrapper";
      }
      return theClass;
    },
    elementType() {
      return this.isFirst ? "div" : "li";
    },
    itemClass() {
      return this.options.icon + " fal icon";
    },
    uploader() {
      return this.options.onChange == "uploadPhoto";
    }
  },
  methods: {
    onFileUpload(e) {
      let f = e.target.files[0];
      this.$emit("perform", "uploadFilez", f);
    },
    handleClick() {
      console.log("handleClick")
      this.options.params
      if (this.options.onClick) {
        this.perform(this.options.onClick, this.options.params);
      }
    },
    handleChange() {
      if (this.options.onChange) {
        this.perform(this.options.onChange, this.options.params);
      }
    },
    setImage(img) {
      this.$emit("perform", "uploadPhoto", img);
    },
    perform(method, params) {
      this.$emit("perform", method, params);
    }
  }
};
</script>
