<template>
  <article>
    <div>
      <i class="fal fa-list-ul icon"></i>
      <div class="title" style="width: 75%; font-size: 1.3em;">
        {{ content.attributes.title }}
      </div>
    </div>

    <div class="description">{{ content.attributes.description }}</div>
    <footer>
      <div class="left">
        <UserAvatar :user="user"></UserAvatar>
        <div class="author-name">
          {{ byLine }}
        </div>
        <div class="created-at">
          {{ when }}
        </div>
      </div>
      <ContentElipsisMenu :canShare="true"
                          :content="content"
                          :rallyId="this.rallyId"
                          :channelId="this.channelId"
                          :can-assign="false"
                          :hide-sentiments="true"
                          contentType="SignupList"/>
    </footer>
  </article>
</template>

<script>
  import ContentMixin from "../../ContentMixin";
  import axios from "axios";
  import {RCS_REPORTS_URL} from "@/common";
  import {userInfo} from "@/common/user-info";
  import {eventBus} from "@/main";

  export default {
    name: "SignupListDetails",
    mixins: [
      ContentMixin,
    ],
    props: ["content", "rally", "channel"],
    created() {
      eventBus.on('download', () => {
        this.downloadList();
        //alert('here')
      })
    },
    methods: {
      downloadList() {
        const token = localStorage.getItem('at');

        axios({
          url: RCS_REPORTS_URL() + "?report_name=signup_lists"
              + "&context_id="
              + this.content.id
              + "&context_channel_id="
              + this.channelId
              + "&timezone="
              + encodeURIComponent(userInfo().timezone),
          method: 'GET',
          responseType: 'blob', // Set the response type to 'blob' for file download
          headers: {
            Authorization: `Bearer ${token}`
          },
        })
            .then(response => {
              // Create a temporary anchor element
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'signups.xls');
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .catch(error => {
              console.error('Error downloading file:', error);
            });
      },
    }
  }
</script>
