<template>
  <article>
    <SignupParticipation :content="content" :channelId="channelId" />
    <div class="click-wrapper" v-on:click="$emit('perform', 'openContent', content)"  style="overflow-y: auto;">
      <div v-if="this.threeLetterMonth" class="calendar-circle">
        <div class="month">{{ threeLetterMonth }}</div>
        <div class="day">{{ twoDigitDay }}</div>
      </div>
      <div class="title">{{ content.attributes.title ? content.attributes.title.substr(0, 75): "" }}</div>
      <div class="description">{{ content.attributes.description ? content.attributes.description.substr(0, 75) : "" }}</div>
      <div v-if="participantsLoaded" class="participants" :style="{ height: computedHeight + 'px' }">
        <div v-for="participant in participations" :key="participant.id" class="participant">
          {{ participant.attributes['neo-avatar']['small-url'] }}
          <img :src="participant.attributes['neo-avatar']['small_url']" />
          {{ participant.attributes['first-name'] }} {{ participant.attributes['last-name']}} [{{ participant.attributes['quantity'] }}]
        </div>
      </div>
      <div v-if="maxParts > 0" class="summary">{{ maxParts - partCount }} of {{ maxParts }} spots available</div>
      <div v-if="maxParts == 0" class="summary">{{ partCount }} participants, with no limits</div>
    </div>
  </article>
</template> 

<script>
import ParticipationMixin from "../../ParticipationMixin";
import moment from "moment-timezone";
import { totalParticipantCount } from "../../../common";
import { mapState, mapActions, mapGetters } from "vuex";
import ContentMixin from "@/components/ContentMixin.vue";
import { eventBus } from "../../../main.js";

export default {
  name: "SignupListed",
  mixins: [ContentMixin, ParticipationMixin],
  props: ["content", "channel", "rally"],
  data: function() {
    return {
      participations: [],
      participantsLoaded: false,
      totalCount: 0
    };
  },
  computed: {
    ...mapState("participations", ["participationById", "participationsByShareId"]),
    participationsState(){
      return this.participationsByShareId[this.shareId]
    },
    computedHeight() {
      return this.participations.length * 25;
    },
    maxParts() {
      return this.content.attributes["max-participants"]; 
    },
    partCount() {
      let t = 0
        this.participations.forEach(p => {
              if (p.attributes['quantity']  && parseInt(p.attributes['quantity']) ){
              t = t + parseInt(p.attributes['quantity'])
            }
        })
      return t
    },
    threeLetterMonth() {
      return this.content.attributes["converted-start"] ?
          moment(this.content.attributes["converted-start"], "YYY-MM-DD").format("MMM") :  (
              (this.content.attributes["from-date"] && this.content.attributes["to-date"]) ? moment(this.content.attributes["from-date"], "MM-DD-YYYY").format("MMM") : null)
    },
    twoDigitDay() {
      return this.content.attributes["converted-start"] ?
          moment(this.content.attributes["converted-start"], "YYY-MM-DD").format("DD") :
          (this.content.attributes["from-date"] ? moment(this.content.attributes["from-date"], "MM-DD-YYYY").format("DD") : null);
    },
    /*myParticipation() {
      return this.participationById[this.content.id + "follower" + this.myUserId];
    }*/
  },
  created() {
    this.getParticipations();
    eventBus.on('getParticipations' , (p) => {
        //this.participants = this.participationsState
        this.getParticipations()
    })
  },
  methods: {
    ...mapActions("participations", ["fetchParticipations"]), 
    async getParticipations() {
      let participationResponse = await this.fetchParticipations({ contextId: this.shareId });
      this.participations = participationResponse.data
      //this.totalCount = totalParticipantCount(participationResponse.data, this.participationById , "assigned"); 
      this.participantsLoaded = true;
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
