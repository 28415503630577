import axios from "axios";
import jwt_decode from "jwt-decode";
import {
  router,
  RPNS_URL,
  RAS_URL,
  RAS_PW_URL,
  RAS_USER_URL,
  RCS_URL,
  RMEMS_URL,
  RUS_URL,
  RUS_EMAILS_URL,
  RH_URL,
  buildServiceParamMap,
  performGet,
  buildMapByValueName,
  CID,
  setRefreshToken,
  RAS_MASQUERADE_URL,
  HB_LOGGING
} from "../../common";
import { Capacitor } from "@capacitor/core";
import { PushNotification } from '@capacitor/push-notifications';
import { eventBus } from "../../main.js";
import { userInfo } from "../../common/user-info";
import { authHeader } from "../../common/auth-header";
import jwtInterceptor from "../../common/jwt-interceptor";
import notificationSettings from "@/components/user_settings/NotificationSettings.vue";
import { App } from '@capacitor/app';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications } from "@capacitor/push-notifications";
import { Preferences } from '@capacitor/preferences';
import { globalVueRef } from "../../main.js";
import { Storage } from '@capacitor/preferences';

//const user = JSON.parse(localStorage.getItem('user'));
const state = {
  alert: { type: null, message: null },
  status: { loggedIn: false, loggingIn: false, error: false },
  user: null,
  usersById: {},
  userPrefs: [],
  userEmails: [],
  userNotificationSettings: null,
  accountStatus: { errorMessage: null, error: false },
};

const getters = {
  alert: (state) => {
    return state.alert;
  },
  user: (state) => {
    var lsuser = userInfo();
    if (state.user) {
      return { ...state.user, ...lsuser };
    } else {
      return lsuser;
    }
  },
  usersById: (state) => {
    return state.usersById;
  },
  userEmails: (state) => {
    return state.userEmails;
  },
  userPrefs: (state) => {
    return state.userPrefs;
  },
  userNotificationSettings: (state) => {
    return state.userNotificationSettings;
  },
};

const actions = {
  async clearUsersState({ commit, state }) {
    commit("clearState");
  },

  async logout({ commit }) {
    localStorage.setItem("at", null);
    localStorage.removeItem("user");
    await Preferences.remove({ key: "user" });
    commit("login", null);
  },

  async reloadUserAfterRefresh({ commit }, params) {
    commit("login", userInfo());
  },
  async createAccount({ commit }, params) {
    const { firstName, lastName, password, timezone, email } = params;
    //localStorage.removeItem("user");
    const requestOptions = {
      method: "POST",
      url: RAS_USER_URL(),
      headers: { "Content-Type": "application/json" },
      //updatethis
      data: {
        data: {
          type: "users",
          attributes: {
            client_version: "6.4.17",
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            time_zone: timezone,
            client_id: CID(),
          },
        },
      },
    };
    let response = "";
    try {
      response = await axios(requestOptions);

      console.log(response.data.data.attributes["access-token"]);
      var decodedToken = jwt_decode(
        response.data.data.attributes["access-token"]
      );

      //@TODO: need to remove rendundant user_id field
      const user = {
        id: decodedToken.user.id,
        user_id: decodedToken.user.id,
        access_token: response.data.data.attributes["access-token"],
        refresh_token: response.data.data.attributes["refresh-token"],
        email: email,
        timezone: decodedToken.user.tz,
      };
      // this.dispatch("user/fetchUserAvatar", { user });
      localStorage.setItem("at", user.access_token);
      localStorage.setItem("user", JSON.stringify(user));
      await Preferences.set({
        key: "user",
        value: JSON.stringify(user),
      });

      /* this.dispatch("user/fetchUsersById", {
        userIds: [decodedToken.user.id],
        loggedInUser: true
      });
*/
      commit("login", user);
      //this.dispatch("user/loadPushStuff", { user });
      this.dispatch("user/startJWTTokenRefresh", { user });
    } catch (e) {
      //this._vm.$log.debug("Login error", e);
      if (e.response) {
        // The server responded with a status code other than 2xx (success)
        console.log("API Error Status:", e.response.status);
        console.log("API Error Data:", e.response.data);
        console.log("error: ", e.response.data.errors[0].detail); // The response data with error details
        console.log("API Error Headers:", e.response.headers);
      }
      console.log("ERROR");
      console.log(e);

      commit("accountCreationError", e.response.data.errors[0].detail);
    }
  },

  async getAnonymousToken({ commit }, params) {
    const { userId, rallyId, email } = params;

    const requestOptions = {
      method: "POST",
      url: RAS_URL(),
      headers: { "Content-Type": "application/json" },
      //updatethis
      data: {
        client_version: "6.4.17",
        user_id: userId,
        rally_id: rallyId,
        grant_type: "anonymous",
        client_id: CID()
      }
    };
    try {
      const response = await axios(requestOptions);
      //var decodedToken = jwt_decode(response.data.access_token);
      console.log("GOT ANONYMOUSE TOKEN: " + response.data.access_token);
      return response.data.access_token;
    } catch (e) {
      this._vm.$log.debug("Login error", e);
      commit("loginError");
    }

  },

  async login({ commit }, params) {
    const { username, password } = params;
    if (HB_LOGGING()){
      try{
          globalVueRef.$honeybadger.setContext({user: username})
        } catch (e){
          console.log(e)
        }
    }
    //localStorage.removeItem("user");
    commit("loginRequest", { username });
    const requestOptions = {
      method: "POST",
      url: RAS_URL(),
      headers: { "Content-Type": "application/json" },
      //updatethis
      data: {
        client_version: "6.4.17",
        email: username,
        password: password,
        grant_type: "password",
        client_id: CID()
      } //

    };
    try {
      const response = await axios(requestOptions);
      var decodedToken = jwt_decode(response.data.access_token);
      //@TODO: need to remove rendundant user_id field
      const user = {
        id: decodedToken.user.id,
        user_id: decodedToken.user.id,
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
        email: username,
        timezone: decodedToken.user.tz,
      };
      // this.dispatch("user/fetchUserAvatar", { user });
      localStorage.setItem("at", user.access_token);
      localStorage.setItem("user", JSON.stringify(user));
      await Preferences.set({
        key: "user",
        value: JSON.stringify(user),
      });
      this.dispatch("user/fetchUsersById", {
        userIds: [decodedToken.user.id],
        loggedInUser: true,
      });

      commit("login", user);
      ///this.dispatch("user/loadPushStuff", { user });
      this.dispatch("user/startJWTTokenRefresh", { user });
    } catch (e) {
      console.log(e)
      //this._vm.$log.debug("Login error", e);
      commit("loginError");
    }
  },

  async resetPassword({ commit }, params) {
    const { token, password } = params;
    const requestOptions = {
      method: "POST",
      url: RAS_PW_URL(),
      headers: { "Content-Type": "application/json" },
      data: {
        data: {
          type: "passwords",
          attributes: { ras_reset_password_token: token, password: password },
        },
      },
    };
    try {
      const response = await axios(requestOptions);
    } catch (e) {
      console.log(e)
      //this._vm.$log.debug("Login error", e);
      commit("loginError");
    }
  },

  async changeUserPassword({ commit }, params) {
    const { updatedUser, password } = params;
    const requestOptions = {
      method: "PATCH",
      url: RAS_PW_URL() + "/1",
      headers: { "Content-Type": "application/json" },
      data: {
        data: {
          type: "passwords",
          attributes: { user_id: updatedUser.id, password: password },
        },
      },
    };
    try {
      const response = await jwtInterceptor(requestOptions);
    } catch (e) {
      //this._vm.$log.debug("Password Could Not Be Changed", e);
      commit("loginError");
    }
  },
  async getMasqueradeToken({ commit, state }, inparams) {
    const { userId } = inparams;
    const requestOptions = {
      method: "GET",
      url: RAS_MASQUERADE_URL() + "/" + userId,
      headers: { "Content-Type": "application/json" },
    };
    try {
      const response = await jwtInterceptor(requestOptions);
      const newToken = response.data.data.attributes["masquerade-token"];
      var decodedToken = jwt_decode(newToken);
      var user = {
        id: decodedToken.user.id,
        user_id: decodedToken.user.id,
        access_token: newToken,
        timezone: decodedToken.user.tz,
      };
      let jsonUser = JSON.stringify(user);
      localStorage.setItem("mat", localStorage.getItem("at"));
      localStorage.setItem("at", newToken);
      localStorage.setItem("user", jsonUser);

      return response.data.data.attributes["masquerade-token"];
    } catch (e) {
      console.log(e)
      //this._vm.$log.debug("Login error", e);
      commit("loginError");
    }
  },
  async resetPasswordEmail({ commit }, params) {
    const { email } = params;
    const requestOptions = {
      method: "POST",
      url: RAS_PW_URL(),
      headers: { "Content-Type": "application/json" },
      data: { data: { type: "passwords", attributes: { email: email } } },
    };
    try {
      const response = await axios(requestOptions);
    } catch (e) {
      console.log(e)
      //this._vm.$log.debug("Login error", e);
      commit("loginError");
    }
  },
  //  Started GET "/api/v1/users.json?id%5B%5D=22812&id%5B%5D=303137&id%5B%5D=16630&include=avatar&page%5Bsize%5D=50"
  async fetchUsersById({ commit }, inparams) {
    let user = userInfo();
    const { userIds, rallyId, loggedInUser = false } = inparams;
    var jsonparams = {
      id: userIds,
      "page[size]": 50,
    };
    jsonparams["include"] = loggedInUser
      ? "avatar,notification_setting,user_preferences,user_emails"
      : "avatar";
    const url = loggedInUser
      ? RUS_URL() + "/" + userIds[0] + ".json"
      : RUS_URL();
    try {
      const response = await performGet(jsonparams, url, user);

      commit("setUsersById", { users: [response.data.data] });
      //fetch the avatars!
      this.dispatch("contents/setIncludedById", {
        included: response.data.included,
        avatarType: "user",
      });
      if (loggedInUser && response.data.included) {
        user.timezone = response.data.data.attributes["time-zone"];
        user.is_rallyhood_admin =
          response.data.data.attributes["is-rallyhood-admin"];
        response.data.included.forEach((inc) => {
          if (inc.type == "notification-settings") {
            commit("setUserNotificationSettings", { settings: inc });
          } else if (inc.type == "user-preferences") {
            commit("setUserPrefs", { prefs: inc });
          } else if (inc.type == "user-emails") {
            commit("setUserEmails", { email: inc });
          }
        });
      }
    } catch (e) {
      console.log(e);
      if (!document.getElementById("vue_component_name_id")) {
        localStorage.removeItem("at");
        localStorage.removeItem("user");
        //await Storage.remove({ key: "user" });
        router.push(`/login`);
        location.reload();
      }
    }
  },

  /* Notification Settings
  Started PUT "/api/v1/users/303137.json" for 127.0.0.1 at 2022-05-10 07:39:33 -0500
  Processing by Api::V1::UsersController#update as JSON
  Parameters: {"data"=>{"type"=>"users", "attributes"=>{"id"=>"303137", "first_name"=>"Jasonnnnn", "last_name"=>"Topliff", "zip_code"=>"", "time_zone"=>"Central Time (US & Canada)", 
  "locale"=>"en", "email"=>"jason.topliff@rallyhood.com", "created_via"=>nil, "created_at"=>"2018-07-17T17:36:45.000Z", "updated_at"=>"2022-05-10T12:38:10.000Z", "deleted_at"=>nil, 
  "client_id"=>nil, "current_position"=>"testing", "additional_info"=>"", "expertise"=>"", "primary_phone"=>"", "facebook_url"=>"", "linkedin_url"=>"", "twitter_url"=>"", 
  "nickname"=>"", "address"=>"", "city"=>"66086", "state"=>"", "country"=>nil, "creator_id"=>303137, "is_rallyhood_admin"=>false, "claimed"=>true, "editable"=>true, "deletable"=>false,
  "viewable"=>false, "links"=>{"self"=>"http://localhost:3100/api/v1/users/303137"}, "notification_setting_attributes"=>{"my_reminders"=>"0", "daily_digest"=>"0", 
  "notify_immediately"=>"0", "invited"=>"0", "invitation_sent"=>"0", "invitation_accepted"=>"0", "join_requested"=>"0", "join_approved"=>"0", "event_assigned"=>"0", 
  "content_shared"=>"0"}}}, "id"=>"303137"}
  */
  /*Started PUT "/api/v1/users/303137.json" for 127.0.0.1 at 2022-05-09 16:31:01 -0500
  Processing by Api::V1::UsersController#update as JSON
  Parameters: {"data"=>{"type"=>"users", "attributes"=>{"id"=>"303137", "first_name"=>"Jasonnnnn", "last_name"=>"Topliff", "zip_code"=>"", "time_zone"=>"Central Time (US & Canada)", 
  "locale"=>"en", "email"=>"jason.topliff@rallyhood.com", "created_via"=>nil, "created_at"=>"2018-07-17T17:36:45.000Z", "updated_at"=>"2022-05-09T21:28:58.000Z", "deleted_at"=>nil, 
  "client_id"=>nil, "current_position"=>"testing", "additional_info"=>"", "expertise"=>"", "primary_phone"=>"", "facebook_url"=>"", "linkedin_url"=>"", "twitter_url"=>"", 
  "nickname"=>"", "address"=>"", "city"=>"66086", "state"=>"", "country"=>nil, "creator_id"=>303137, "is_rallyhood_admin"=>false, "claimed"=>true, "editable"=>true, 
  "deletable"=>false, "viewable"=>false, "links"=>{"self"=>"http://localhost:3100/api/v1/users/303137"}}}, "id"=>"303137"}*/
  async updateUserInfo({ commit, state }, inparams) {
    const { updatedUser, userNotificationSettings, updateAll, userPrefs } =
      inparams;
    const user = userInfo();
    const createdAttributes = {
      id: updatedUser.id,
      first_name: updatedUser.attributes["first-name"],
      last_name: updatedUser.attributes["last-name"],
      zip_code: updatedUser.attributes["zip-code"],
      time_zone: updatedUser.attributes["time-zone"],
      current_position: updatedUser.attributes["current-position"],
      additional_info: updatedUser.attributes["additional-info"],
      expertise: updatedUser.attributes["expertise"],
      primary_phone: updatedUser.attributes["primary_phone"],
      facebook_url: updatedUser.attributes["facebook-url"],
      linkedin_url: updatedUser.attributes["linkedin-url"],
      twitter_url: updatedUser.attributes["twitter-url"],
      nickname: updatedUser.attributes["nickname"],
      address: updatedUser.attributes["address"],
      city: updatedUser.attributes["city"],
      state: updatedUser.attributes["state"],
      email: updatedUser.attributes["email"],
    };
    if (
      Object.prototype.hasOwnProperty.call(
        userNotificationSettings,
        "attributes"
      )
    ) {
      createdAttributes["notification_setting_attributes"] = {
        my_reminders:
          userNotificationSettings.attributes["my-reminders"] === true
            ? "1"
            : "0",
        daily_digest:
          userNotificationSettings.attributes["daily-digest"] === true
            ? "1"
            : "0",
        event_assigned:
          userNotificationSettings.attributes["event-assigned"] === true
            ? "1"
            : "0",
        notify_immediately:
          userNotificationSettings.attributes["notify-immediately"] === true
            ? "1"
            : "0",
        invited:
          userNotificationSettings.attributes["invited"] === true ? "1" : "0",
        invitation_sent:
          userNotificationSettings.attributes["invitation-sent"] === true
            ? "1"
            : "0",
        invitation_accepted:
          userNotificationSettings.attributes["invitation-accepted"] === true
            ? "1"
            : "0",
        join_requested:
          userNotificationSettings.attributes["join-requested"] === true
            ? "1"
            : "0",
        join_approved:
          userNotificationSettings.attributes["join-approved"] === true
            ? "1"
            : "0",
        content_shared:
          userNotificationSettings.attributes["content-shared"] === true
            ? "1"
            : "0", //,
      };
    }
    if (userPrefs.length > 0) {
      const id = userPrefs[0].id;
      const preferences_type = userPrefs[0].attributes["preferences-type"];
      const value = userPrefs[0].attributes["value"];
      createdAttributes["user_preferences_attributes"] = [
        {
          id: id,
          preferences_type: preferences_type,
          value: value,
        },
      ];
    }

    if (updateAll === "true") {
      createdAttributes["is_custom"] = false;
    } else {
      createdAttributes["update_defaults"] = true;
    }
    let requestOptions = {
      method: "PUT",
      url: RUS_URL() + "/" + updatedUser.id + ".json",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        data: {
          type: "users",
          attributes: createdAttributes,
        },
      },
    };

    const response = await jwtInterceptor(requestOptions);
    this.dispatch("user/updateUserMembershipInfo", {
      updatedUser: updatedUser,
      updateAll: updateAll,
    });
  },

  async updateUserProfile({ commit, state }, inparams) {
    const { updatedUser, updateAll } = inparams;
    const user = userInfo();

    const createdAttributes = {
      id: updatedUser.id,
      first_name: updatedUser.attributes["first-name"],
      last_name: updatedUser.attributes["last-name"],
      zip_code: updatedUser.attributes["zip-code"],
      time_zone: updatedUser.attributes["time-zone"],
      current_position: updatedUser.attributes["current-position"],
      additional_info: updatedUser.attributes["additional-info"],
      expertise: updatedUser.attributes["expertise"],
      primary_phone: updatedUser.attributes["primary_phone"],
      facebook_url: updatedUser.attributes["facebook-url"],
      linkedin_url: updatedUser.attributes["linkedin-url"],
      twitter_url: updatedUser.attributes["twitter-url"],
      nickname: updatedUser.attributes["nickname"],
      address: updatedUser.attributes["address"],
      city: updatedUser.attributes["city"],
      state: updatedUser.attributes["state"],
      email: updatedUser.attributes["email"],
    };

    let requestOptions = {
      method: "PUT",
      url: RUS_URL() + "/" + updatedUser.id + ".json",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        data: {
          type: "users",
          attributes: createdAttributes,
        },
      },
    };

    const response = await jwtInterceptor(requestOptions);
    this.dispatch("user/updateUserMembershipInfo", {
      updatedUser: updatedUser,
      updateAll: updateAll,
    });
  },

  async updateUserMembershipInfo({ commit, state }, inparams) {
    const { updatedUser, updateAll } = inparams;
    const user = userInfo();
    const updateAttributes = {
      user_id: updatedUser.id,
      first_name: updatedUser.attributes["first-name"],
      last_name: updatedUser.attributes["last-name"],
      zip: updatedUser.attributes["zip-code"],
      position: updatedUser.attributes["current-position"],
      additional_info: updatedUser.attributes["additional-info"],
      expertise: updatedUser.attributes["expertise"],
      primary_phone: updatedUser.attributes["primary_phone"],
      facebook_url: updatedUser.attributes["facebook-url"],
      linkedin_url: updatedUser.attributes["linkedin-url"],
      twitter_url: updatedUser.attributes["twitter-url"],
      nickname: updatedUser.attributes["nickname"],
      address: updatedUser.attributes["address"],
      city: updatedUser.attributes["city"],
      state: updatedUser.attributes["state"],
    };

    if (updateAll === "true") {
      updateAttributes["is_custom"] = false;
    } else {
      updateAttributes["update_defaults"] = true;
    }

    let requestOptions = {
      method: "PATCH",
      url: RMEMS_URL(),
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        data: {
          type: "memberships",
          attributes: updateAttributes,
        },
      },
    };
    const response = await jwtInterceptor(requestOptions);
  },

  async addSecondaryUserEmail({ commit, state }, inparams) {
    const { updatedUser, email } = inparams;
    const user = userInfo();

    const updateAttributes = {
      email: email,
      user_id: user.id,
      primary: false,
    };

    let requestOptions = {
      method: "POST",
      url: RUS_EMAILS_URL(),
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        data: {
          type: "user-emails",
          attributes: updateAttributes,
        },
      },
    };
    const response = await jwtInterceptor(requestOptions);
  },
  async removeSecondaryUserEmail({ commit, state }, inparams) {
    const { userEmailId } = inparams;
    const user = userInfo();

    let requestOptions = {
      method: "DELETE",
      url: RUS_EMAILS_URL() + `/${userEmailId}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await jwtInterceptor(requestOptions);
    commit("setUserEmailById", { userEmailId: userEmailId });
  },

  //Started POST "/api/v1/devices/register" for 127.0.0.1 at 2020-12-15 19:22:28 -0600
  //Processing by Api::V1::DevicesController#register as JSON
  //Parameters: {"device"=>{"user_id"=>"1234", "udid"=>"abcd", "device_type"=>"0"}}
  async registerUserDevice({ commit }, inparams) {
    const { deviceId } = inparams;
    const user = userInfo();
    const deviceType = Capacitor.getPlatform() == "ios" ? 0 : 1;
    const requestOptions = {
      method: "POST",
      url: RPNS_URL(),
      headers: {
        //'Content-Type': 'application/json',
        //'Referrer-Policy': 'none',
        // 'X-Permitted-Cross-Domain-Policies': 'none',
      },
      data: {
        device: {
          user_id: user.user_id,
          udid: deviceId,
          device_type: deviceType,
          app_id: "New App",
        },
      },
    };
    try {
      const response = await jwtInterceptor(requestOptions);
    } catch (e) {
      console.log(e);
    }
  },

  async startJWTTokenRefresh({ commit }, inparams) {
    if (
      Capacitor.getPlatform() == "android" ||
      Capacitor.getPlatform() == "ios"
    ) {
      return;
    } else {
      const { email } = inparams;
      setInterval(
        function () {
          this.dispatch("user/getRhJWT", { email });
        }.bind(this),
        1200000
      );
    }
  },
  async getRhJWT({ commit }, params) {
    if (
      Capacitor.getPlatform() == "android" ||
      Capacitor.getPlatform() == "ios"
    ) {
      return;
    } else {
      const { email } = params;

      const requestOptions = {
        method: "GET",
        url: RH_URL() + "users/jwt_token",
        /*updatethis for local testing in RH*/
        //url: "http://localhost:3000/" + "users/jwt_token"
      };
      const response = await axios(requestOptions);
      //updatethis to test locally
      //let response = {data: {access_token:"", refresh_token:""}}
      //const user = { id: decodedToken.user.id, user_id: decodedToken.user.id, access_token: response.data, email };
      //localStorage.setItem("user", JSON.stringify(user));
      var decodedToken = jwt_decode(response.data.access_token);
      //@TODO: need to remove rendundant user_id field
      const user = {
        id: decodedToken.user.id,
        user_id: decodedToken.user.id,
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
        email: email,
        timezone: decodedToken.user.tz,
      };
      // this.dispatch("user/fetchUserAvatar", { user });
      localStorage.setItem("at", user.access_token);
      localStorage.setItem("user", JSON.stringify(user));
      await Storage.set({
        key: "user",
        value: JSON.stringify(user),
      });
      this.dispatch("user/fetchUsersById", {
        userIds: [decodedToken.user.id],
        loggedInUser: true,
      });

      commit("login", user);
    }
  },

  async loadPushStuff({ commit }, inparams) {
    //this._vm.$log.error("loadPushStuff!");
    try {
      App.addListener('appStateChange',  isActive  =>  function() {
        console.log("checking appstate change " + isActive)
        if (!isActive) {
          // App went to background
          // Save anything you fear might be lost
          console.log("APP INACTIVE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
        } else {
          // App went to foreground
          // restart things like sound playing
          //let user = await userInfoPersistent()
          //localStorage.setItem("user", JSON.stringify(user));
          console.log("APP ACTIVE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
          //this.backToDashboard()
        }
      });
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting

      PushNotifications.addListener("registration", token => {
        alert(JSON.stringify(token))
        localStorage.setItem("msg_token", token.value);
        this.dispatch("user/registerUserDevice", { deviceId: token.value });
      });

      PushNotifications.addListener("registrationError", error => {
        alert(JSON.stringify(error))
        console.log("PushNotifications addListener error" + error);
      });

      PushNotifications.requestPermission().then(result => {
        try {
          PushNotifications.register();
        }
        catch (e){
          alert(JSON.stringify(e))
          console.log("PushNotifications register error" + e);
        }
      });
/*
      try {
        PushNotifications.register();
      }
      catch (e){
        console.log("PushNotifications register error" + e);
      }
*/
      LocalNotifications.requestPermissions().then((result) => {
        alert(JSON.stringify(result))
        console.log("Local Notifications result:" + result)
      })


      PushNotifications.addListener( 
        "pushNotificationReceived",
        n => {
          let body = "";
          let title = "";
          let url = "";
          alert(JSON.stringify(n))
          try {
            if (Capacitor.getPlatform() == "ios") {
              body = n.data.aps.alert.body;
              title = n.data.aps.alert.subtitle;
              url = n.data.data.url;
            } else {
              body = n.data.body;
              title = n.data.title;
              url = n.data.content_url;
              let not = {
                notifications: [
                  {
                    id: 1,
                    body: body,
                    title: title,
                    extra: {url: url}
                  }
                ]
              };
              this.dispatch('user/flashSuccess', title + " : " + body);
              const result = LocalNotifications.schedule(not);
            }
          } catch (e) {
            console.log(e);
          }
          if (body && body.indexOf("direct message from") >= 0) {
            try {
              this.dispatch("channels/addNewPrivateMessage", {
                message: { title: body }
              });
            } catch (e) {
              alert(JSON.stringify(e))
              console.log(e);
            }
            const alert = { type: "success", message: title + " " + body }; //notification.data.aps.alert.body };
            commit("setAlert", alert);
          }
        }
      );

      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        n => {
            try{
              alert(JSON.stringify(n))
              let body = "";
              let title = "";
              let url = "";
              if (Capacitor.getPlatform() == "ios") {
                body = n.notification.data.aps.alert.body;
                title = n.notification.data.aps.alert.title;
                url = n.notification.data.data.url;
              } else {
                body = n.notification.data.body;
                title = n.notification.data.title;
                url = n.notification.data.content_url;
              }

              if (body && body.indexOf("direct message from") >= 0) {
                eventBus.emit('toggleBell', '')
              } else {
                const arr = url.split("/")
                let rid = ""
                arr.forEach(s => {
                  try{
                    if (!rid){
                      rid = parseInt(s)
                    }
                  } catch (e){
                    console.log(e)
                  }
                });
                if (rid){
                  router.push("/" + rid + "/messages")
                }
            }
          } catch (e){
            alert(JSON.stringify(e))
            console.log(e)
          }
          //router.push(`/rallies`);
        }
      );
      LocalNotifications.addListener('localNotificationActionPerformed', function (n) {
        try{
        const url = n && n.notification && n.notification.extra && n.notification.extra.url ? n.notification.extra.url : ""
        const arr = url.split("/")
        let rid = ""
        arr.forEach(s => {
          try{
            if (!rid){
              rid = parseInt(s)
            }
          } catch (e){
            console.log(e)
          }
        });
        //alert("rid:" + rid)
        if (rid){
          router.push("/" + rid + "/messages")
        }
      } catch (e){
        alert(JSON.stringify(e))
        console.log(e)
      }
      });

    } catch (e) {
      alert(JSON.stringify(e))
     //this._vm.$log.error(e);
     console.log(e)
    }
    alert("end of loadPushStuff")
  },//end of loadPushStuff



  async setUsersById({ commit, state }, inparams) {
    commit("setUsersById", inparams);
  },

  flashFailure({ commit }, message) {
    const alert = { type: "failure", message: message };
    commit("setAlert", alert);
  },

  flashSuccess({ commit }, message) {
    const alert = { type: "success", message: message };
    commit("setAlert", alert);
  },

  flashWarning({ commit }, message) {
    const alert = { type: "warning", message: message };
    commit("setAlert", alert);
  },

  flashNotice({ commit }, message) {
    const alert = { type: "notice", message: message };
    commit("setAlert", alert);
  },


};

const mutations = {
  clearState: (state) => {
    // state.usersById = {};
    state.user = null;
    state.accountStatus = {}
    state.status= { loggedIn: false, loggingIn: false, error: false }
  },
  setAlert: (state, params) => {
    const { type, message } = params;
    state.alert.type = type;
    state.alert.message = message;

    setTimeout(
      function () {
        state.alert.type = null;
        state.alert.message = null;
      }.bind(this),
      10000
    );
  },
  setUserEmails: (state, params) => {
    const { email } = params;
    state.userEmails.push(email);
  },
  setUserEmailById: (state, params) => {
    const { userEmailId } = params;
    const index = state.userEmails.findIndex(
      (email) => email.id === userEmailId
    );
    if (index !== -1) {
      state.userEmails.splice(index, 1);
    }
  },
  setUserPrefs: (state, params) => {
    const { prefs } = params;
    state.userPrefs.push(prefs);
  },
  setUserNotificationSettings: (state, params) => {
    const { settings } = params;

    state.userNotificationSettings = settings;
  },
  setUsersById: (state, params) => {
    const { users } = params;
    var newMap = buildMapByValueName("id", users);
    //populate the currently logged in user attributes
    if (state.user && !state.user.attributes) {
      var newUserInfo = newMap[state.user.id];
      state.user = { ...state.user, ...newUserInfo };
    }
    state.usersById = { ...state.usersById, ...newMap };
  },

  loginRequest(state, user) {
    state.status = { loggingIn: true, loggedIn: false, error: false };
    state.user = null;
  },
  loginError(state) {
    state.status = { loggingIn: false, error: true };
    state.user = null;
  },
  accountCreationError(state, error) {
    state.accountStatus = { error: true, errorMessage: error };
  },
  login: (state, user) => {
    state.status = {
      loggedIn: true,
      loggingIn: false,
      error: false,
      refreshing: false,
    };
    state.user = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
