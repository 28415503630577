<template>
  <article>
    <div class="left">
      <div class="title" style="padding-left: 20px;">{{ content.attributes.title }}</div>
      <div class="date">{{ doFormatDateEvent(content.attributes) }}</div>

      <div class="repeat-summary" v-if="content.attributes['repeat-summary']">Repeats {{ content.attributes['repeat-summary']}}</div>
      <div class="description" v-html="content.attributes.description"></div>


    </div>
    <footer>
      <div class="left">
        <UserAvatar :user="user"></UserAvatar>
        <div class="author-name">{{ byLine }}</div>
        <div class="created-at">{{ when }}</div>
      </div>
      <div class="right">
        <button
          v-if="canEdit"
          @click="$emit('perform', 'edit')"
          class="action"
        >
          <i class="fal fa-pencil"></i>
        </button>
        <span v-if="this.isAdmin" >
          <AdminSignupParticipation :content="content" :channelId="channelId" :rallyId="parseInt(this.rallyId)" />
        </span>
        <SignupParticipation :content="content" :channelId="channelId" />
      </div>
    </footer>
    <hr />
    <div v-if="maxParts > 0" class="summary">{{ maxParts - partCount }} of {{ maxParts }} spots available</div>
    <div v-if="maxParts == 0" class="summary">{{ partCount }} participants, with no limits</div>
    <div v-if="(this.show_names_to_admin &&  this.isAdmin) ||  this.show_names_to_admin == 0 ">
      <div
        v-for="p in participations"
        :key="p.id"
        class="participant"
      >
        <span v-if=p.attributes.quantity>
        <img :src="p.attributes['neo-avatar']['small_url']" />
        {{ p.attributes['first-name'] }} {{ p.attributes['last-name']}} [{{ p.attributes['quantity'] }}]
        </span>
      </div>
    </div>
    <ContentAttachments v-on:perform="perform"
                        :readOnly="true"
                        :content="content"
                        :rallyId="parseInt(this.rallyId)"
                        :channelId="parseInt(this.contextShare.attributes['context-channel-id'])" />
    <Comments
      :shareId="content.relationships['context-share'].data.id" 
      :avatarFile="content.attributes['creator-avatar']"
      :channelId="parseInt(this.contextShare.attributes['context-channel-id'])"
      :rallyId="this.rallyId"
    />
 </article>
</template>

<script>
import ContentMixin from "../../ContentMixin";
import ParticipationMixin from "../../ParticipationMixin";
import moment from "moment-timezone";
import { totalParticipantCount, formatDateNoTimezoneConversion, formatDateSignup, railsTzToMomentTz } from "../../../common";
import { userInfo } from "../../../common/user-info"
import { eventBus } from "../../../main.js";
import { mapState, mapActions, mapGetters } from "vuex";

  export default {
    name: "SignupEventDetails",
    mixins: [
      ContentMixin,
    ],
    props: ["content", "channel", "rally", "contextShare"],
    data() {
      return {
        show_names_to_admin: (this.content.attributes['privacy'] == 1),
        participations: [],
        participantsLoaded: false,
        totalCount: 0
      };
    },
    computed: {
    ...mapState("participations", ["participationById", "participationsByShareId"]),
    ...mapState("user", ["usersById"]),
    ...mapState("shares", ["sharesByContentId"]),
    participationsState(){
      return this.participationsByShareId[this.content.relationships['context-share'].data.id]
    },
    maxParts() {
      return this.content.attributes["max-participants"];
    },
    /*partCount() {
      //let pc = totalParticipantCount(this.content.relationships.participations.data, this.participationById, "assigned");
      let pc = this.totalCount
      let retVal = (pc == 0  ?  this.myCount : pc)
      if (typeof retVal == "undefined"){
        return 0
      } else {
        return retVal
      }
    },*/ 
    partCount() {
      let t = 0
        this.participations.forEach(p => {
              if (p.attributes['quantity']  && parseInt(p.attributes['quantity']) ){
              t = t + parseInt(p.attributes['quantity'])
            }
        })
      return t
    },
    threeLetterMonth() {
      return this.content.attributes["converted-start"] ? moment(this.content.attributes["converted-start"], "YYY-MM-DD").format("MMM") :  (this.content.attributes["from-date"] ? moment(this.content.attributes["from-date"], "MM-DD-YYYY").format("MMM") : null)
    },
    twoDigitDay() {
      return this.content.attributes["converted-start"] ? moment(this.content.attributes["converted-start"], "YYY-MM-DD").format("DD") : (this.content.attributes["from-date"] ? moment(this.content.attributes["from-date"], "MM-DD-YYYY").format("DD") : null);
    },
    myParticipation() {
      return this.participationById[this.content.id + "follower" + this.myUserId];
    },
    participants() {
      return this.content.relationships.participations.data
    },
    contextShareComputed() {
        var rallyId = this.$attrs.rallyId
        if (this.shares && this.shares.filter){
          return this.shares.filter(function(s) {
            //console.log(s.attributes['context_rally_id'] == parseInt(rallyId));
            return s.attributes['context-rally-id'] == rallyId;
          })[0];
        } else {
          return {} 
        }
      },
    shares(){
      return this.sharesByContentId[parseInt(this.content.id)]
    },
  },
    async created() {
      await this.getParticipations()
      eventBus.on('getParticipations' , (p) => {
        //this.participants = this.participationsState
        this.getParticipations();
      })
    },
  methods: {
    ...mapActions("participations", ["fetchParticipations"]),
    async getParticipations() {
      let participationResponse = await this.fetchParticipations({ contextId: this.content.relationships['context-share'].data.id, pageSize: 100 });
      this.participations = participationResponse.data
      //this.totalCount = totalParticipantCount(participationResponse.data, this.participationById , "assigned"); 
      this.participantsLoaded = true;

    },
    doFormatDateEvent(d){
      return formatDateSignup(d, railsTzToMomentTz(userInfo().timezone))//formatDateNoTimezoneConversion(d, railsTzToMomentTz(userInfo().timezone))
    },
    getParticipantUser(id){
      return this.usersById[this.participationById[id+ ""].attributes["user-id"]]
    },
    getParticipantUserId(id){
      var u = this.getParticipantUser(id)
      return u ? u.id : ""
    },
    getParticipantName(id){
      var u = this.getParticipantUser(id)
      return u ? u.attributes["first-name"] + " " +  u.attributes["last-name"] : ""
    }
    
  }
  };
</script>
